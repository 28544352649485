import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  id?: string;
  className?: string;
  style?: any;
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
};

const ButtonGroup: React.FC<Props> = ({
  children,
  id,
  className,
  style,
  direction = 'row'
}) => {
  const props = { id, className, style, direction };

  return <StyledDiv {...props}>{children}</StyledDiv>;
};

const StyledDiv = styled.div<Props>`
  display: flex;
  flex-direction: ${(p) => p.direction};

  ${(p) =>
    p.direction === 'row' &&
    css`
      & > button + button {
        margin-left: ${(p) => p.theme.spacing(1)};
      }
    `}

  ${(p) =>
    p.direction === 'row-reverse' &&
    css`
      & > button + button {
        margin-right: ${(p) => p.theme.spacing(1)};
      }
    `}

  ${(p) =>
    p.direction === 'column' &&
    css`
      & > button + button {
        margin-top: ${(p) => p.theme.spacing(1)};
      }
    `}

  ${(p) =>
    p.direction === 'column-reverse' &&
    css`
      & > button + button {
        margin-bottom: ${(p) => p.theme.spacing(1)};
      }
    `}
`;

export default ButtonGroup;
