import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CWBLoadingCrest, getCurUser } from 'cwb-react';
import {
  BreadCrumb,
  Button,
  Container,
  Link,
  Page,
  toast,
  Typography
} from 'components';
import EditRepresentation from './EditRepresentation';
import RepresentationTable from './RepresentationTable';
import UnsavedChangesModal from './UnsavedChangesModal';
import LoadingIcon from 'images/icon-cwb-logo-grey.svg';
import {
  Change,
  RepresentationData,
  NewRepresentationData
} from 'types/Representation';
import { computeChanges } from 'helpers/representation';

type Props = {} & StateProps & DispatchProps;

function Representation({
  actorProfile,
  isLoading,
  reps,
  setPageTitle,
  getRepresentationData,
  saveChanges,
  setIsLoading,
  setReps
}: Props) {
  const { t } = useTranslation();

  const [isEdit, setIsEdit] = useState(false);
  const [repsCopy, setRepsCopy] = useState([]);

  const [modal, setModal] = useState({
    isOpen: false
  });

  useEffect(() => {
    setPageTitle(t('Representation'));
  }, [setPageTitle, t]);

  useEffect(() => {
    if (actorProfile) {
      setIsLoading(true);
      getRepresentationData({ user: getCurUser(), actorProfile }).then(() => {
        setIsLoading(false);
      });
    }
  }, [actorProfile, getRepresentationData, setIsLoading]);

  const handleClick = () => {
    setRepsCopy(reps);
    setIsEdit(true);
  };

  const handleSave = (
    existingReps: RepresentationData[],
    newReps: NewRepresentationData[]
  ) => {
    setIsLoading(true);

    newReps = newReps.filter((rep) => rep.agency && rep.agent);
    saveChanges({
      user: getCurUser(),
      changes: computeChanges(repsCopy, existingReps, newReps),
      actorProfile
    })
      .then((payload: any) => {
        if (payload && payload.message) {
          toast.warning(t(payload.message));
        }
        toast.success(t('Representation updated.'));
      })
      .catch(async (err: any) => {
        console.log(err);
        try {
          const errorPayload = await err.json();
          if (errorPayload.message) {
            if (errorPayload.error) {
              toast.error(<span>{t(errorPayload.message)}</span>);
            } else {
              toast.warning(<span>{t(errorPayload.message)}</span>);
            }
          }
        } catch (ex) {
          console.log(ex);
        }

        toast.error(
          <span>
            {t('Some updates were not completed. Please retry or')}
            &nbsp;
            <Link
              external
              href={process.env.REACT_APP_SUPPORT}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('contact support')}
            </Link>
            .
          </span>
        );
      })
      .finally(async () => {
        await getRepresentationData({ user: getCurUser(), actorProfile });
        setIsLoading(false);
      });
    setIsEdit(false);
  };

  const handleCancel = (
    existingReps: RepresentationData[],
    newReps: NewRepresentationData[]
  ) => {
    const changes = computeChanges(
      repsCopy,
      existingReps,
      newReps.filter((rep) => rep.agency && rep.agent)
    );
    if (!changes.length) return setIsEdit(false);

    setModal((modal) => ({
      ...modal,
      isOpen: true
    }));
  };

  const handleClickModal = () => {
    setIsEdit(false);
    setModal((modal) => ({
      ...modal,
      isOpen: false
    }));
  };

  return (
    <>
      <BreadCrumb to="/representation">{t('Representation')}</BreadCrumb>
      <Page>
        <Container>
          <StyledSection>
            <Typography>
              {t(
                'Add or edit your representation. Agents will be notified when representation is added/updated.'
              )}
            </Typography>
            {!isEdit && (
              <Button disabled={isLoading} onClick={handleClick}>
                {t('Edit/Add')}
              </Button>
            )}
          </StyledSection>
          {isLoading ? (
            <CWBLoadingCrest customLogoSrc={LoadingIcon} />
          ) : (
            <>
              {isEdit ? (
                <EditRepresentation
                  data={reps}
                  onSave={handleSave}
                  onCancel={handleCancel}
                />
              ) : (
                <RepresentationTable />
              )}
            </>
          )}
        </Container>
      </Page>
      <UnsavedChangesModal
        {...modal}
        onClick={handleClickModal}
        onClose={() => setModal((modal) => ({ ...modal, isOpen: false }))}
      />
    </>
  );
}

const StyledSection = styled.section`
  height: 36px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(p) => p.theme.spacing(5)};
`;

interface StateProps {
  actorProfile: any;
  isLoading: boolean;
  reps: RepresentationData[];
}

const mapState = (state: RootState) =>
  ({
    actorProfile: state.app.actorProfile,
    isLoading: state.representation.isLoading,
    reps: state.representation.reps
  } as Partial<Props>);

interface DispatchProps {
  setPageTitle: (pageTitle: string) => any;
  getRepresentationData: (payload: { user: any; actorProfile: any }) => any;
  saveChanges: (payload: {
    user: any;
    changes: Change[];
    actorProfile: any;
  }) => any;
  setIsLoading: (isLoading: boolean) => any;
  setReps: (reps: RepresentationData[]) => any;
}

const mapDispatch = (dispatch: Dispatch) => ({
  setPageTitle: dispatch.app.setPageTitle,
  getRepresentationData: dispatch.representation.getRepresentationData,
  saveChanges: dispatch.representation.saveChanges,
  setIsLoading: dispatch.representation.setIsLoading,
  setReps: dispatch.representation.setReps
});

export default connect(mapState, mapDispatch)(Representation);
