import React from 'react';
import styled from 'styled-components';
import {
  Modal as ReactstrapModal,
  ModalHeader as ReactstrapModalHeader,
  ModalBody as ReactstrapModalBody,
  ModalFooter as ReactstrapModalFooter
} from 'reactstrap';
import Typography from './Typography';

type Props = {
  id?: string;
  className?: string;
  style?: any;
};

type RootProps = {
  isOpen: boolean;
  onClose: () => void;
};

type HeaderProps = {
  destructive?: boolean;
  onClose: () => void;
};

const Modal: React.FC<Props & RootProps> = ({
  children,
  id,
  className,
  style,
  isOpen,
  onClose
}) => {
  const props = { id, className, style };

  return (
    <StyledModal isOpen={isOpen} toggle={onClose} {...props}>
      {children}
    </StyledModal>
  );
};

const ModalHeader: React.FC<Props & HeaderProps> = ({
  children,
  id,
  className,
  style,
  destructive,
  onClose
}) => {
  const props = { id, className, style, destructive };

  return (
    <StyledModalHeader toggle={onClose} {...props}>
      <Typography component="span" variant="h2">
        {children}
      </Typography>
    </StyledModalHeader>
  );
};

const ModalBody: React.FC<Props> = ({ children, id, className, style }) => {
  const props = { id, className, style };

  return <StyledModalBody {...props}>{children}</StyledModalBody>;
};

const ModalFooter: React.FC<Props> = ({ children, id, className, style }) => {
  const props = { id, className, style };

  return <StyledModalFooter {...props}>{children}</StyledModalFooter>;
};

const StyledModal = styled(ReactstrapModal)`
  margin-top: 103px;
`;

const StyledModalHeader = styled(({ destructive, ...props }) => (
  <ReactstrapModalHeader {...props} />
))`
  padding-top: ${(p) => p.theme.spacing(2)};
  padding-bottom: ${(p) => p.theme.spacing(1)};
  border-top: 6px solid
    ${(p) =>
      p.destructive
        ? p.theme.palette.system.error
        : p.theme.palette.button.normal};
  border-bottom: none;
`;

const StyledModalBody = styled(ReactstrapModalBody)`
  padding-top: ${(p) => p.theme.spacing(1)};
  padding-bottom: ${(p) => p.theme.spacing(1)};
`;

const StyledModalFooter = styled(ReactstrapModalFooter)`
  justify-content: flex-start;
  padding-bottom: ${(p) => p.theme.spacing(2)};
  border-top: none;
`;

export { Modal, ModalHeader, ModalBody, ModalFooter };
