import React from 'react';
import styled from 'styled-components';

type Props = {
  id?: string;
  className?: string;
  style?: any;
};

const Container: React.FC<Props> = ({ children, id, className, style }) => {
  const props = {
    id,
    className,
    style
  };

  return <StyledDiv {...props}>{children}</StyledDiv>;
};

const StyledDiv = styled.div<Props>`
  max-width: 1440px;
  margin: auto;
  padding: ${(p) => p.theme.spacing(0, 6)};
`;

export default Container;
