import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import ChevronRightIcon from 'images/chevron_right.svg';

const Context = React.createContext([]);

const useBreadCrumbContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('Missing BreadcrumbProvider.');
  }

  return context;
};

const BreadCrumbProvider: React.FC = ({ children }) => {
  const portalNodeState = useState();

  return (
    <Context.Provider value={portalNodeState}>{children}</Context.Provider>
  );
};

const BreadCrumbPortal = () => {
  const portalNodeRef = useRef();
  const [, setPortalNode] = useBreadCrumbContext();

  useEffect(() => {
    setPortalNode(portalNodeRef.current);
  }, [setPortalNode]);

  return (
    <nav>
      <StyledUl ref={portalNodeRef} />
    </nav>
  );
};

const BreadCrumb: React.FC<{ external?: boolean; to: string; props?: any }> = ({
  children,
  external = false,
  to,
  ...props
}) => {
  const [portalNode] = useBreadCrumbContext();

  const getLinkElement = (external: boolean, to: string) => {
    return (
      <StyledLi {...props}>
        {external ? (
          <ExternalLink href={to}>{children}</ExternalLink>
        ) : (
          <InternalLink to={to}>{children}</InternalLink>
        )}
      </StyledLi>
    );
  };

  return portalNode
    ? ReactDOM.createPortal(getLinkElement(external, to), portalNode)
    : null;
};

const StyledUl = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const StyledLi = styled.li`
  display: flex;
  align-items: center;

  &:not(:first-child)::before {
    width: 16px;
    height: 16px;
    margin: ${(p) => p.theme.spacing(0, 0.5)};
    content: url(${ChevronRightIcon});
  }
`;

const linkStyles = css`
  color: #758196;
  font-size: ${(p) => p.theme.typography.fontSize.breadCrumbs};
  font-weight: ${(p) => p.theme.typography.fontWeight.breadCrumbs};
  line-height: ${(p) => p.theme.typography.lineHeight.breadCrumbs};
  text-decoration: none;

  &:hover {
    color: #758196;
  }
`;

const ExternalLink = styled.a`
  ${linkStyles}
`;

const InternalLink = styled(Link)`
  ${linkStyles}
`;

export { BreadCrumb, BreadCrumbPortal, BreadCrumbProvider };
