import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useState
} from 'react';
import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getCurUser } from 'cwb-react';
import { Col, Container, Row } from 'reactstrap';
import {
  Button,
  ButtonGroup,
  Paper,
  toast,
  Typography,
  IconText
} from 'components';
import DeleteRepresentationModal from './DeleteRepresentationModal';
import EditExisting from './EditExisting';
import EditNew from './EditNew';
import AddIcon from 'images/add_circle.svg';
import { CustomChangeEvent, DropdownOption } from 'types/shared';
import {
  AgencyAgent,
  NewRepresentationData,
  RepresentationData,
  RepresentationError
} from 'types/Representation';
import { computeChanges } from 'helpers/representation';

type Props = {
  data: RepresentationData[];
  onSave: (
    existingReps: RepresentationData[],
    newReps: NewRepresentationData[]
  ) => void;
  onCancel: (
    existingReps: RepresentationData[],
    newReps: NewRepresentationData[]
  ) => void;
} & StateProps &
  DispatchProps;

function EditRepresentation({
  data,
  onSave: handleSave,
  onCancel,
  actorProfile,
  errorsExisting,
  errorsNew,
  reps,
  setErrorsExisting,
  setErrorsNew
}: Props) {
  const { t } = useTranslation();

  const [existingReps, setExistingReps] = useState<RepresentationData[]>(data);
  const [newReps, setNewReps] = useState<NewRepresentationData[]>([]);

  const [modal, setModal] = useState({
    agencyName: '',
    index: 0,
    isDeleteExisting: false,
    isOpen: false
  });

  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      const hasChanges = Boolean(
        computeChanges(
          reps,
          existingReps,
          newReps.filter((rep) => rep.agency && rep.agent)
        ).length
      );
      if (!hasChanges) return;
      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [reps, existingReps, newReps]);

  useEffect(() => {
    if (!errorsExisting.length && existingReps.length) {
      setErrorsExisting(
        existingReps.map((rep) => ({
          contactNo: '',
          email: ''
        }))
      );
    }
  }, [existingReps, errorsExisting, setErrorsExisting]);

  useEffect(() => {
    return () => {
      setErrorsExisting([]);
      setErrorsNew([]);
    };
  }, [setErrorsExisting, setErrorsNew]);

  const handleChangeExisting = (e: ChangeEvent, targetIndex: number) => {
    const eventTarget = e.target as HTMLInputElement;
    setExistingReps(
      existingReps.map((rep, index) => {
        return index === targetIndex
          ? {
              ...rep,
              [eventTarget.name]: eventTarget.value
            }
          : rep;
      })
    );
  };

  // const handleChangeDefaultExisting = (targetIndex: number) => {
  //   setExistingReps(
  //     existingReps.map((rep, index) => ({
  //       ...rep,
  //       isDefault: index === targetIndex
  //     }))
  //   );
  //
  //   setNewReps(
  //     newReps.map((rep) => ({
  //       ...rep,
  //       isDefault: false
  //     }))
  //   );
  // };

  const handleDeleteExisting = (targetIndex: number) => {
    setModal({
      agencyName: existingReps[targetIndex].agency,
      index: targetIndex,
      isDeleteExisting: true,
      isOpen: true
    });
  };

  const handleDeleteConfirmExisting = (targetIndex: number) => {
    let r = existingReps.filter((rep, index) => index !== targetIndex);
    // if (existingReps[targetIndex].isDefault) {
    //   r = r.map((rep, index) => {
    //     return index === 0
    //       ? {
    //           ...rep,
    //           isDefault: true
    //         }
    //       : rep;
    //   });
    // }
    setExistingReps(r);
    setErrorsExisting(
      errorsExisting.filter((_, index) => index !== targetIndex)
    );
  };

  const handleSaveAgent = (agencyAgent: AgencyAgent, targetIndex: number) => {
    const {
      agentFirstName,
      agentLastName,
      agentPhone,
      agentEmail
    } = agencyAgent;

    const newAgent = {
      label: `${agentFirstName} ${agentLastName}`,
      value: 0,
      firstName: agentFirstName,
      lastName: agentLastName,
      contactNo: agentPhone,
      email: agentEmail
    };

    setExistingReps(
      existingReps.map((rep, index) => {
        return index === targetIndex
          ? {
              ...rep,
              agentId: 0,
              agent: newAgent,
              agentOptions: [...rep.agentOptions, newAgent],
              contactNo: agentPhone,
              email: agentEmail
            }
          : rep;
      })
    );
  };

  const handleSelectAgentExisting = (
    e: CustomChangeEvent,
    targetIndex: number
  ) => {
    setExistingReps(
      existingReps.map((rep, index) => {
        return index === targetIndex
          ? {
              ...rep,
              [e.target.name]: e.target.value,
              agentId: e.target.value.value,
              contactNo: e.target.value.contactNo,
              email: e.target.value.email
            }
          : rep;
      })
    );
  };

  const setAgentOptionsExisting = useCallback(
    (options: DropdownOption[], targetIndex: number) => {
      setExistingReps((existingReps) =>
        existingReps.map((rep, index) => {
          return index === targetIndex
            ? { ...rep, agentOptions: options }
            : rep;
        })
      );
    },
    []
  );

  const handleChangeNew = (e: ChangeEvent, targetIndex: number) => {
    const eventTarget = e.target as HTMLInputElement;
    setNewReps(
      newReps.map((rep, index) => {
        return index === targetIndex
          ? {
              ...rep,
              [eventTarget.name]: eventTarget.value
            }
          : rep;
      })
    );
  };

  // const handleChangeDefaultNew = (targetIndex: number) => {
  //   setNewReps(
  //     newReps.map((rep, index) => ({
  //       ...rep,
  //       isDefault: index === targetIndex
  //     }))
  //   );
  //
  //   setExistingReps(
  //     existingReps.map((rep) => ({
  //       ...rep,
  //       isDefault: false
  //     }))
  //   );
  // };

  const handleDeleteNew = (targetIndex: number) => {
    if (newReps[targetIndex].agency) {
      setModal({
        agencyName: newReps[targetIndex].agency.label,
        index: targetIndex,
        isDeleteExisting: false,
        isOpen: true
      });
    } else {
      handleDeleteConfirmNew(targetIndex);
    }
  };

  const handleDeleteConfirmNew = (targetIndex: number) => {
    setNewReps(newReps.filter((rep, index) => index !== targetIndex));
    setErrorsNew(errorsNew.filter((_, index) => index !== targetIndex));
    // if (newReps[targetIndex].isDefault) {
    //   setExistingReps(
    //     existingReps.map((rep, index) => {
    //       return index === 0
    //         ? {
    //             ...rep,
    //             isDefault: true
    //           }
    //         : rep;
    //     })
    //   );
    // }
  };

  const handleSaveAgencyAgent = (
    agencyAgent: AgencyAgent,
    targetIndex: number
  ) => {
    const {
      agencyId,
      agencyName,
      agentFirstName,
      agentLastName,
      agentPhone,
      agentEmail
    } = agencyAgent;

    setNewReps(
      newReps.map((rep, index) => {
        return index === targetIndex
          ? {
              ...rep,
              agency: {
                label: agencyName,
                value: agencyId
              },
              agencyInput: agencyName,
              agent: {
                label: `${agentFirstName} ${agentLastName}`,
                value: 0,
                firstName: agentFirstName,
                lastName: agentLastName
              },
              contactNo: agentPhone,
              email: agentEmail,
              isDefault: true
            }
          : {
              ...rep,
              isDefault: false
            };
      })
    );

    setExistingReps(
      existingReps.map((rep) => ({
        ...rep,
        isDefault: false
      }))
    );
  };

  const handleSelectAgency = (e: CustomChangeEvent, targetIndex: number) => {
    const { value: item } = e.target;
    const user = getCurUser();
    const selfAgencyId = actorProfile.profileDefaults[0].agencyId;
    const selfAgentId = actorProfile.profileDefaults[0].agentId;
    const isSelf = item ? item.value === selfAgencyId : false;

    setNewReps(
      newReps.map((rep, index) => {
        if (index === targetIndex) {
          return item
            ? {
                ...rep,
                [e.target.name]: e.target.value,
                agencyInput: item ? item.label : '',
                agent: isSelf
                  ? {
                      label: t('Self'),
                      value: selfAgentId
                    }
                  : null,
                contactNo: isSelf ? user.phone : '',
                email: isSelf ? user.email : '',
                isDefault: true,
                isSelf
              }
            : {
                ...rep,
                [e.target.name]: e.target.value,
                agencyInput: '',
                agencyOptions: [],
                agent: null,
                agentOptions: [],
                contactNo: '',
                email: '',
                isDefault: false,
                isSelf: false
              };
        }

        return {
          ...rep,
          isDefault: false
        };
      })
    );

    if (isSelf) {
      setExistingReps(
        existingReps.map((rep) => ({
          ...rep,
          isDefault: false
        }))
      );
    }
  };

  const handleSelectAgentNew = (e: CustomChangeEvent, targetIndex: number) => {
    setNewReps(
      newReps.map((rep, index) => {
        return index === targetIndex
          ? {
              ...rep,
              [e.target.name]: e.target.value,
              contactNo: e.target.value.contactNo,
              email: e.target.value.email,
              isDefault: true
            }
          : {
              ...rep,
              isDefault: false
            };
      })
    );

    setExistingReps(
      existingReps.map((rep) => ({
        ...rep,
        isDefault: false
      }))
    );
  };

  const setAgencyOptions = (options: DropdownOption[], targetIndex: number) => {
    setNewReps((newReps) =>
      newReps.map((rep, index) => {
        return index === targetIndex ? { ...rep, agencyOptions: options } : rep;
      })
    );
  };

  const setAgentOptionsNew = (
    options: DropdownOption[],
    targetIndex: number
  ) => {
    setNewReps((newReps) =>
      newReps.map((rep, index) => {
        return index === targetIndex ? { ...rep, agentOptions: options } : rep;
      })
    );
  };

  const addRepresentationRow = () => {
    const rosters = existingReps.length + newReps.length;
    if (rosters >= 5) {
      toast.error(
        t(
          'Sorry, you cannot add a new representation. You may have reached the limit, please try to remove an existing one before adding it again or contact our support.'
        )
      );
    } else {
      const rep: NewRepresentationData = {
        agency: null,
        agencyInput: '',
        agencyOptions: [],
        agent: null,
        agentOptions: [],
        contactNo: '',
        email: '',
        isDefault: false,
        isSelf: false
      };

      setNewReps([...newReps, rep]);
      setErrorsNew([
        ...errorsNew,
        {
          agencyInput: '',
          agent: '',
          contactNo: '',
          email: ''
        }
      ]);
    }
  };

  const isSubmittable =
    newReps.reduce((acc, cur) => {
      return acc && Boolean(cur.agency && cur.agent);
    }, true) &&
    !Boolean(
      [...errorsExisting, ...errorsNew].reduce((acc, cur) => {
        return acc + Object.values(cur).reduce((_acc, _cur) => _acc + _cur);
      }, '')
    );

  return (
    <>
      <StyledPaper>
        <StyledContainer fluid>
          <StyledRow>
            {[t('Agency'), t('Agent'), t('Phone'), t('Email')].map(
              (header, index) => (
                <Col key={index} xs={[1, 2].includes(index) ? 2 : 4}>
                  <Typography
                    align="left"
                    color="darkGrey"
                    component="h2"
                    variant="bodyBold"
                  >
                    {header}
                  </Typography>
                </Col>
              )
            )}
          </StyledRow>
          <EditExisting
            existingReps={existingReps}
            onChange={handleChangeExisting}
            // onChangeDefault={handleChangeDefaultExisting}
            onDelete={handleDeleteExisting}
            onSaveAgencyAgent={handleSaveAgent}
            onSelectAgent={handleSelectAgentExisting}
            setAgentOptions={setAgentOptionsExisting}
          />
          <EditNew
            existingReps={existingReps}
            newReps={newReps}
            onChange={handleChangeNew}
            // onChangeDefault={handleChangeDefaultNew}
            onDelete={handleDeleteNew}
            onSaveAgencyAgent={handleSaveAgencyAgent}
            onSelectAgency={handleSelectAgency}
            onSelectAgent={handleSelectAgentNew}
            setAgencyOptions={setAgencyOptions}
            setAgentOptions={setAgentOptionsNew}
          />
        </StyledContainer>
        <AddButton
          startIcon={<img alt="" src={AddIcon} />}
          onMouseDown={(e: MouseEvent) => {
            e.preventDefault();
            addRepresentationRow();
          }}
        >
          {t('Add more')}
        </AddButton>
        <ButtonGroup>
          <Button
            disabled={!isSubmittable}
            onClick={() => handleSave(existingReps, newReps)}
          >
            {t('Save')}
          </Button>
          <Button
            variant="secondary"
            onMouseDown={() => onCancel(existingReps, newReps)}
          >
            {t('Cancel')}
          </Button>
        </ButtonGroup>
      </StyledPaper>
      {modal.isOpen && (
        <DeleteRepresentationModal
          {...modal}
          onClose={() => setModal((modal) => ({ ...modal, isOpen: false }))}
          onDeleteExisting={handleDeleteConfirmExisting}
          onDeleteNew={handleDeleteConfirmNew}
        />
      )}
    </>
  );
}

const StyledPaper = styled(Paper)`
  padding: ${(p) => p.theme.spacing(2, 3, 3)};
`;

const StyledContainer = styled(Container)`
  padding: 0;

  & .col-2,
  .col-4:not(:last-child) {
    padding-right: 0;
  }

  & .col-4:last-child {
    display: flex;
    align-items: center;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: ${(p) => p.theme.spacing(1)};
`;

const AddButton = styled(IconText)`
  margin-bottom: ${(p) => p.theme.spacing(2)};
`;

interface StateProps {
  actorProfile: any;
  errorsExisting: RepresentationError[];
  errorsNew: RepresentationError[];
  reps: RepresentationData[];
}

const mapState = (state: RootState) => ({
  actorProfile: state.app.actorProfile,
  errorsExisting: state.representation.errorsExisting,
  errorsNew: state.representation.errorsNew,
  reps: state.representation.reps
});

interface DispatchProps {
  setErrorsExisting: (errors: RepresentationError[]) => any;
  setErrorsNew: (errors: RepresentationError[]) => any;
}

const mapDispatch = (dispatch: Dispatch) => ({
  setErrorsExisting: dispatch.representation.setErrorsExisting,
  setErrorsNew: dispatch.representation.setErrorsNew
});

export default connect(mapState, mapDispatch)(EditRepresentation);
