import React, { ChangeEvent, FocusEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import Typography from './Typography';
import ErrorIcon from 'images/error.svg';

type RootProps = {
  id?: string;
  className?: string;
  style?: any;
  endAdornment?: ReactNode;
  errorMessage?: string;
};

type InputProps = {
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  error?: boolean;
  label?: string;
  name?: string;
  placeholder?: string;
  readOnly?: boolean;
  ref?: any;
  value?: string;
  onBlur?: (event: FocusEvent) => void;
  onChange?: (event: ChangeEvent) => void;
  onFocus?: (event: FocusEvent) => void;
};

const TextInput: React.FC<RootProps & InputProps> = React.forwardRef(
  (
    {
      id,
      className,
      style,
      autoComplete,
      autoFocus = false,
      disabled = false,
      endAdornment,
      error = false,
      errorMessage,
      label,
      name,
      placeholder,
      readOnly = false,
      value,
      onBlur,
      onChange,
      onFocus
    },
    ref
  ) => {
    const rootProps = {
      id,
      className,
      style,
      endAdornment
    };

    const inputProps = {
      autoComplete,
      autoFocus,
      disabled,
      error,
      name,
      placeholder,
      readOnly,
      ref,
      value,
      onBlur,
      onChange,
      onFocus
    };

    return (
      <>
        {label ? (
          <StyledLabel {...rootProps}>
            {label}
            <StyledInput type="text" {...inputProps} />
            {endAdornment}
          </StyledLabel>
        ) : (
          <>
            {endAdornment ? (
              <AdornmentContainer {...rootProps}>
                <StyledInput type="text" {...inputProps} />
                {endAdornment}
              </AdornmentContainer>
            ) : (
              <StyledInput type="text" {...rootProps} {...inputProps} />
            )}
          </>
        )}
        {error && errorMessage && (
          <StyledDiv>
            <StyledImg src={ErrorIcon} />
            <Typography color="error" variant="captionBold">
              {errorMessage}
            </Typography>
          </StyledDiv>
        )}
      </>
    );
  }
);

const rootStyles = css`
  width: 100%;
`;

const adornmentContainerStyles = css`
  position: relative;
`;

const adornmentStyles = css`
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 0;
  right: 0;
  outline: none;
  transform: translate(-9px, -9px);
`;

const StyledLabel = styled.label<RootProps>`
  ${rootStyles}
  ${(p) =>
    p.endAdornment &&
    css`
      ${adornmentContainerStyles}

      & > *:last-child {
        ${adornmentStyles}
      }
    `}

  color: ${(p) => p.theme.typography.color.darkGrey};
  margin: 0;
  font-size: ${(p) => p.theme.typography.fontSize.inputLabel};
  font-weight: ${(p) => p.theme.typography.fontWeight.inputLabel};
  line-height: ${(p) => p.theme.typography.lineHeight.inputLabel};

  & > input {
    margin-top: ${(p) => p.theme.spacing(1)};
  }
`;

const AdornmentContainer = styled.div`
  ${rootStyles}
  ${adornmentContainerStyles}

  & > *:last-child {
    ${adornmentStyles}
  }
`;

const StyledInput = styled.input<InputProps>`
  ${(p) =>
    !p.label &&
    css`
      ${rootStyles}
    `}

  height: 35px;
  padding: 0 12px;
  color: ${(p) => p.theme.typography.color.darkGrey};
  background-color: ${(p) => p.theme.palette.common.white};
  border: 1px solid ${(p) => p.theme.palette.common.grey};
  border-radius: 4px;
  font-size: ${(p) => p.theme.typography.fontSize.input};
  font-weight: ${(p) => p.theme.typography.fontWeight.input};
  line-height: ${(p) => p.theme.typography.lineHeight.input};
  outline: none;

  &:focus {
    border-color: ${(p) => p.theme.palette.button.normal};
  }

  &:read-only {
    color: ${(p) => p.theme.typography.color.lightGrey};
  }

  ${(p) =>
    p.error &&
    css`
      border-color: ${(p) => p.theme.palette.system.error};
    `}
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(p) => p.theme.spacing(1)};
`;

const StyledImg = styled.img`
  margin: ${(p) => p.theme.spacing(0, '4px', '2px', 0)};
`;

export default TextInput;
