import { createModel } from '@rematch/core';
import { RootModel } from 'models';
import { ProfileManagementAPI } from 'apis';

interface State {
  actorProfile: any;
  pageTitle: string;
}

const app = createModel<RootModel>()({
  state: {
    actorProfile: null,
    pageTitle: ''
  } as State,
  reducers: {
    setActorProfile(state, actorProfile: any) {
      return { ...state, actorProfile };
    },
    setPageTitle(state, pageTitle: string) {
      return { ...state, pageTitle };
    }
  },
  effects: (dispatch: any) => ({
    async getActorProfile(clientId: number) {
      ProfileManagementAPI.getActorProfile(clientId)
        .then((res) => res.json())
        .then((data) => {
          dispatch.app.setActorProfile(data);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  })
});

export default app;
