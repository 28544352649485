import React, { MouseEvent, ReactNode } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

type Props = {
  id?: string;
  className?: string;
  style?: any;
  external?: boolean;
  href?: string;
  rel?: string;
  startIcon?: ReactNode;
  target?: string;
  to?: string;
  variant?: 'primary' | 'secondary' | 'primaryAction' | 'secondaryAction';
  onClick?: (event: MouseEvent) => void;
  onMouseDown?: (event: MouseEvent) => void;
};

const Link: React.FC<Props> = ({
  children,
  id,
  className,
  style,
  external,
  href,
  rel,
  startIcon,
  target,
  to = '',
  variant = 'primary',
  onClick,
  onMouseDown
}) => {
  const props = {
    id,
    className,
    style,
    $startIcon: startIcon,
    variant,
    onClick,
    onMouseDown
  };

  const externalProps = {
    href,
    rel,
    target
  };

  const internalProps = {
    to
  };

  return external ? (
    <StyledA {...props} {...externalProps}>
      {startIcon}
      {children}
    </StyledA>
  ) : (
    <StyledLink {...props} {...internalProps}>
      {startIcon}
      {children}
    </StyledLink>
  );
};

const StyledA = styled.a<Props & { $startIcon: ReactNode }>`
  width: max-content;
  height: max-content;
  cursor: pointer;
  font-size: ${(p) => p.theme.typography.fontSize.link};
  line-height: ${(p) => p.theme.typography.lineHeight.link};

  ${(p) =>
    p.variant === 'primary' &&
    css`
      color: ${(p) => p.theme.palette.button.normal};
      font-weight: ${(p) => p.theme.typography.fontWeight.normal};
      text-decoration: underline;

      &:hover {
        color: ${(p) => p.theme.palette.button.normal};
      }
    `}

  ${(p) =>
    p.variant === 'secondary' &&
    css`
      color: ${(p) => p.theme.typography.color.darkGrey};
      font-weight: ${(p) => p.theme.typography.fontWeight.normal};
      text-decoration: underline;

      &:hover {
        color: ${(p) => p.theme.typography.color.darkGrey};
      }
    `}

  ${(p) =>
    p.variant === 'primaryAction' &&
    css`
      color: ${(p) => p.theme.palette.button.normal};
      font-weight: ${(p) => p.theme.typography.fontWeight.bold};

      &:hover {
        color: ${(p) => p.theme.palette.button.normal};
        text-decoration: none;
      }
    `}

  ${(p) =>
    p.variant === 'secondaryAction' &&
    css`
      color: ${(p) => p.theme.typography.color.darkGrey};
      font-weight: ${(p) => p.theme.typography.fontWeight.bold};

      &:hover {
        color: ${(p) => p.theme.typography.color.darkGrey};
        text-decoration: none;
      }
    `}

  ${(p) =>
    p.$startIcon &&
    css`
      & > :first-child {
        width: 16px;
        height: 16px;
        margin: ${(p) => p.theme.spacing('-0.5px', 1, '0.5px', 0)};
      }

      ${(p) =>
        p.variant === 'primary' &&
        css`
          color: ${(p) => p.theme.typography.color.darkGrey};
          font-weight: ${(p) => p.theme.typography.fontWeight.bold};
          text-decoration: none;

          &:hover {
            color: ${(p) => p.theme.typography.color.darkGrey};
            text-decoration: none;
          }
        `}
    `}
`;

const StyledLink = StyledA.withComponent(ReactRouterLink);

export default Link;
