import React from 'react';
import styled from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';

type Props = {
  id?: string;
  className?: string;
  style?: any;
  placement?: any;
  tip: string;
};

const Tooltip: React.FC<Props> = ({
  children,
  id,
  className,
  style,
  placement = 'top',
  tip
}) => {
  const props = {
    className,
    style
  };

  const anchorId = id ? id : tip.replace(/[^A-Za-z]/g, '');

  return (
    <StyledDiv id={anchorId} key={`${anchorId}${tip}`} {...props}>
      {children}
      <UncontrolledTooltip
        delay={{ show: 0, hide: 0 }}
        fade={false}
        placement={placement}
        target={anchorId}
      >
        {tip}
      </UncontrolledTooltip>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  display: inline-flex;
`;

export default Tooltip;
