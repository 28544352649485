import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './i18n/en/resource.json';
import translationUK from './i18n/en_uk/resource.json';
import translationES from './i18n/es/resource.json';
import translationFR from './i18n/fr/resource.json';
import translationNB from './i18n/nb/resource.json';

const resources = {
  en: {
    translation: translationEN
  },
  en_uk: {
    translation: translationUK
  },
  es: {
    translation: translationES
  },
  fr: {
    translation: translationFR
  },
  nb: {
    translation: translationNB
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  nsSeparator: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
