import { moment } from 'cwb-react';
import i18n from '../i18n';

export const debounce = (func: any, wait: number) => {
  let timeout: number;

  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = window.setTimeout(later, wait);
  };
};

type LanguageChoice = 0 | 1 | 2 | 3 | 99;
type LanguageCode = 'en' | 'fr' | 'en_uk' | 'es' | 'nb';

export const getLanguageCode = (langChoice: LanguageChoice): LanguageCode => {
  let langCode = 'en';

  if (typeof langChoice === 'number') {
    langCode = {
      '0': 'en',
      '1': 'fr',
      '2': 'en_uk',
      '3': 'es',
      '99': 'nb'
    }[langChoice] as LanguageCode;
  } else {
    const browserLang = (
      navigator.languages?.[0] || navigator.language
    ).toLowerCase();
    const locale = browserLang.split('-')[0];
    if (browserLang === 'en-gb') {
      langCode = 'en_uk';
    } else if (['en', 'fr', 'es'].includes(locale)) {
      langCode = locale as LanguageCode;
    }
  }

  return langCode as LanguageCode;
};

export const setLanguage = (langCode: LanguageCode) => {
  i18n.changeLanguage(langCode);
  moment.moment.locale(langCode);
};
