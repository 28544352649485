import qs from 'query-string';
import { getAuthConfig } from 'cwb-react';

const api = process.env.REACT_APP_PM_API;

export const getActorProfileAll = (clientId: number) => {
  return fetch(`${api}/ActorProfile/${clientId}/all`, {
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthConfig().headers
    }
  });
};

// export const updateFavorite = (clientId: number, rosterId: number) => {
//   return fetch(`${api}/ActorProfile/${clientId}/favorite`, {
//     method: 'PUT',
//     cache: 'no-cache',
//     credentials: 'include',
//     headers: {
//       'Content-Type': 'application/json',
//       ...getAuthConfig().headers
//     },
//     body: JSON.stringify({ clientId, rosterId })
//   });
// };

export const getActorProfile = (clientId: number) => {
  return fetch(`${api}/ActorProfile/${clientId}`, {
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthConfig().headers
    }
  });
};

export const getAgencyAgents = (agencyId: number) => {
  return fetch(`${api}/AgencyAgent/${agencyId}`, {
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthConfig().headers
    }
  });
};

export const getAgencies = (name: string) => {
  const queryString = qs.stringify({
    name,
    pageSize: 50
  });

  return fetch(`${api}/AgencyAgent/search?${queryString}`, {
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthConfig().headers
    }
  });
};

export const deleteAgentProfile = (clientId: number, agentId: number) => {
  return fetch(`${api}/AgentProfile/${clientId}/agent/${agentId}`, {
    method: 'DELETE',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthConfig().headers
    }
  });
};

export const getClientAgents = (clientId: number) => {
  return fetch(`${api}/ClientAgent/${clientId}/all`, {
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthConfig().headers
    }
  });
};

export const updateClientAgent = (
  clientId: number,
  payload: {
    agencyId: number;
    existentAgentId: number;
    rosterId: number;
    newAgentId: number;
    agentFirstName?: string;
    agentLastName?: string;
    phone?: string;
    email?: string;
  }
) => {
  return fetch(`${api}/ClientAgent/${clientId}`, {
    method: 'PUT',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthConfig().headers
    },
    body: JSON.stringify({ ...payload })
  });
};

export const addClientAgent = (
  clientId: number,
  agent: {
    agencyId: number;
    agentId: number;
    agencyHomeCity: number;
    agencyName: string;
    agentFirstName: string;
    agentLastName: string;
    phone: string;
    email: string;
    forStudio: boolean;
  }
) => {
  return fetch(`${api}/ClientAgent/${clientId}`, {
    method: 'POST',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthConfig().headers
    },
    body: JSON.stringify({ ...agent })
  });
};

export const updateEmail = (clientId: number, newEmail: string) => {
  return fetch(`${api}/ContactMethod/${clientId}/email`, {
    method: 'PUT',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthConfig().headers
    },
    body: JSON.stringify({ newEmail })
  });
};

export const updatePhone = (clientId: number, newPhone: string) => {
  return fetch(`${api}/ContactMethod/${clientId}/phone`, {
    method: 'PUT',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthConfig().headers
    },
    body: JSON.stringify({ newPhone })
  });
};
