import React, { ChangeEvent, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import TextInput from './TextInput';
import ChevronDown from 'images/chevron_down.svg';
import ChevronUp from 'images/chevron_up.svg';
import FlagCA from 'images/flag_ca.svg';
import FlagUS from 'images/flag_us.svg';

const options = [
  {
    label: 'United States +1',
    value: 'US',
    img: FlagUS
  },
  {
    label: 'Canada +1',
    value: 'CA',
    img: FlagCA
  }
];

type Props = {
  id?: string;
  className?: string;
  style?: any;
  name?: string;
  value?: string;
  onChange?: (event: ChangeEvent) => void;
};

const PhoneInput: React.FC<Props> = ({
  id,
  className,
  style,
  name,
  value,
  onChange
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(options[0]);

  const rootProps = { id, className, style };
  const inputProps = { name, value, onChange };

  return (
    <StyledDiv {...rootProps}>
      <Dropdown isOpen={open} toggle={() => setOpen(!open)}>
        <StyledDropdownToggle open={open}>
          <img alt={selected.label} src={selected.img} />
        </StyledDropdownToggle>
        <DropdownMenu>
          {options.map((opt, index) => (
            <StyledDropdownItem key={index} onClick={() => setSelected(opt)}>
              {opt.label}
            </StyledDropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      <StyledTextInput {...inputProps} />
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  height: 35px;
  display: flex;
  position: relative;
`;

const StyledDropdownToggle = styled(DropdownToggle)`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: ${(p) => p.theme.spacing(1)};
  padding-right: ${(p) => p.theme.spacing(1)};
  background-color: ${(p) => p.theme.palette.common.white};
  border: 1px solid ${(p) => p.theme.palette.common.grey};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;

  &:hover,
  &:focus,
  &&&:active,
  &&&:focus:active {
    background-color: ${(p) => p.theme.palette.common.white};
    border-color: ${(p) => p.theme.palette.common.grey};
  }

  &:focus,
  &&&:active,
  &&&:focus:active {
    box-shadow: inset 0 0 0 1px ${(p) => p.theme.palette.button.normal};
  }

  &::after {
    content: url(${(p) => (p.open ? ChevronUp : ChevronDown)});
    margin-left: ${(p) => p.theme.spacing('5px')};
    margin-right: ${(p) => p.theme.spacing('-2px')};
    ${(p) =>
      p.open
        ? css`
            margin-top: ${(p) => p.theme.spacing('-5px')};
          `
        : css`
            margin-top: ${(p) => p.theme.spacing('-4.5px')};
          `}
  }
`;

const StyledDropdownItem = styled(DropdownItem)`
  color: ${(p) => p.theme.typography.color.darkGrey};
  padding: ${(p) => p.theme.spacing(0.5, 2)};
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;

  &:focus,
  &:hover {
    color: ${(p) => p.theme.typography.color.darkGrey};
    background-color: ${(p) => p.theme.palette.brand.blue}10;
    outline: none;
  }

  &:active {
    color: ${(p) => p.theme.typography.color.darkGrey};
    background-color: ${(p) => p.theme.palette.brand.blue}20;
  }
`;

const StyledTextInput = styled(TextInput)`
  width: calc(100% - 53px);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

export default PhoneInput;
