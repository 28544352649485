import React, {
  MouseEvent,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import Typography from './Typography';
import AddIcon from 'images/add.svg';
import AddIconWhite from 'images/add_white.svg';
import { CustomChangeEvent, DropdownOption } from 'types/shared';

type Props = {
  id?: string;
  className?: string;
  style?: any;
  allowAdd?: boolean;
  isLoading?: boolean;
  name?: string;
  noFilter?: boolean;
  options: DropdownOption[];
  renderInput: (props: any) => ReactNode;
  value: DropdownOption;
  onAdd?: () => void;
  onSelect: (event: CustomChangeEvent) => void;
};

const Autocomplete: React.FC<Props> = ({
  id,
  className,
  style,
  allowAdd,
  isLoading,
  name,
  noFilter,
  options,
  renderInput,
  value,
  onAdd: handleAdd,
  onSelect
}) => {
  const { t } = useTranslation();

  const inputEl = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [imagesToPreload] = useState([AddIcon, AddIconWhite]);

  const inputValue = inputEl.current ? inputEl.current.value : '';
  const isMenuShown = showMenu && inputValue.length >= 2;

  useEffect(() => {
    imagesToPreload.forEach((imgSrc: string) => {
      const img = new Image();
      img.src = imgSrc;
    });
  }, [imagesToPreload]);

  const handleBlur = () => {
    if (!inputEl.current.value) {
      onSelect({
        target: {
          name,
          value: null
        }
      } as CustomChangeEvent);
    } else if (value) {
      onSelect({
        target: {
          name,
          value
        }
      });
    }
    setShowMenu(false);
  };

  const handleFocus = () => {
    setShowMenu(true);
  };

  const handleClick = (item: DropdownOption) => {
    onSelect({
      target: {
        name,
        value: item
      }
    } as CustomChangeEvent);
    setShowMenu(false);
    setTimeout(() => {
      if (inputEl.current) {
        inputEl.current.blur();
      }
    }, 0);
  };

  if (!noFilter) {
    options = options.filter((item) =>
      item.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  }

  const props = { id, className, style };

  return (
    <StyledDiv {...props}>
      {renderInput({ ref: inputEl, onBlur: handleBlur, onFocus: handleFocus })}
      {isMenuShown && (
        <Menu>
          {isLoading ? (
            <StyledLi>{t('Loading...')}</StyledLi>
          ) : (
            <>
              {options.length ? (
                options.map((item) => (
                  <MenuItem
                    key={`${item.label}-${item.value}`}
                    onClick={() => handleClick(item)}
                    onMouseDown={(e: MouseEvent) => e.preventDefault()}
                  >
                    {item.label}
                  </MenuItem>
                ))
              ) : (
                <StyledLi>{t('No options')}</StyledLi>
              )}
              {allowAdd && (
                <StyledMenuItem
                  onClick={handleAdd}
                  onMouseDown={(e: MouseEvent) => e.preventDefault()}
                >
                  <Typography component="span" variant="body">
                    {t('Add')} "{inputEl.current.value.trim()}"
                  </Typography>
                </StyledMenuItem>
              )}
            </>
          )}
        </Menu>
      )}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  position: relative;
`;

const Menu = styled.ul`
  width: 100%;
  max-height: 280px;
  position: absolute;
  top: 40px;
  left: 0;
  padding: ${(p) => p.theme.spacing(0)};
  background-color: ${(p) => p.theme.palette.common.white};
  border: 1px solid ${(p) => p.theme.palette.common.grey};
  border-radius: 4px;
  list-style: none;
  overflow-y: auto;
  z-index: 10;
`;

const baseLiStyles = css`
  padding: ${(p) => p.theme.spacing(0.5, 2)};
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;

  &:first-of-type {
    margin-top: ${(p) => p.theme.spacing(1)};
  }

  &:last-of-type {
    margin-bottom: ${(p) => p.theme.spacing(1)};
  }
`;

const MenuItem = styled.li`
  ${baseLiStyles}

  color: ${(p) => p.theme.typography.color.darkGrey};
  cursor: pointer;

  &:focus,
  &:hover {
    color: ${(p) => p.theme.palette.common.white};
    background-color: ${(p) => p.theme.palette.button.normal};
  }
`;

const StyledLi = styled.li`
  ${baseLiStyles}

  color: ${(p) => p.theme.typography.color.lightGrey};
  cursor: default;
`;

const StyledMenuItem = styled(MenuItem)`
  & > span {
    color: ${(p) => p.theme.palette.button.normal};
  }

  &:hover > span {
    color: ${(p) => p.theme.palette.common.white};
  }

  & > span::before {
    position: relative;
    top: 3px;
    margin-right: ${(p) => p.theme.spacing(0.5)};
    content: url(${AddIcon});
  }

  &:hover > span::before {
    content: url(${AddIconWhite});
  }
`;

export default Autocomplete;
