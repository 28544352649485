const palette = {
  brand: {
    darkBlue: '#0A1F44',
    blue: '#00AAFF',
    lightBlue: '#2ED2FF',
    red: '#FF1870'
  },
  grey: {
    '0': '#FCFCFD',
    '1': '#F8F9FB',
    '2': '#F1F2F4',
    '3': '#C9CED6',
    '4': '#A6AEBC',
    '5': '#8A94A6',
    '6': '#53627C',
    '7': '#364866',
    '8': '#0A1F44'
  },
  system: {
    success: '#0BB07B',
    successDark: '#1F8651',
    error: '#E5084A',
    errorDark: '#C7053F',
    warning: '#FCB704',
    warningDark: '#B36204'
  },
  button: {
    normal: '#0F9BF0',
    hover: '#0892E5',
    pressed: '#0584D7'
  },
  common: {
    black: '#000000',
    white: '#FFFFFF',
    grey: '#D1D5DD',
    lightGrey: '#DEE3EB',
    transparent: 'transparent'
  }
};

const spacingHelper = (...args: (string | number)[]): string => {
  const SCALING = 8;

  const parseNum = (num: number): string => `${num * SCALING}px`;

  const getSpacing = (args: (string | number)[]) => {
    const newArgs: string[] = args.map((arg: string | number): string => {
      return typeof arg === 'number' ? parseNum(arg) : arg;
    });

    return newArgs
      .reduce((acc: string, cur: string): string => {
        return acc.concat(` ${cur}`);
      })
      .trim();
  };

  if (args.length > 4) {
    throw new Error('spacing received too many arguments');
  } else if (args.length < 1) {
    throw new Error('spacing received not enough arguments');
  }

  return getSpacing(args);
};

const CWBTheme = {
  palette: { ...palette },
  spacing: spacingHelper,
  typography: {
    color: {
      black: palette.grey[8],
      darkGrey: palette.grey[7],
      medGrey: palette.grey[6],
      lightGrey: palette.grey[5],
      success: palette.system.successDark,
      error: palette.system.errorDark,
      link: palette.button.normal,
      disabled: palette.grey[3]
    },
    fontFamily: `'Open Sans', sans-serif`,
    fontSize: {
      breadCrumbs: '14px',
      button: '14px',
      input: '14px',
      inputLabel: '14px',
      link: '14px',
      h1: '24px',
      h2: '20px',
      h3: '18px',
      h4: '16px',
      h5: '16px',
      body: '14px',
      bodyBold: '14px',
      caption: '12px',
      captionBold: '12px'
    },
    fontWeight: {
      normal: 'normal',
      bold: 'bold',
      breadCrumbs: '600',
      button: 'bold',
      input: '400',
      inputLabel: '600',
      h1: 'bold',
      h2: 'bold',
      h3: 'bold',
      h4: 'normal',
      h5: 'bold',
      body: 'normal',
      bodyBold: 'bold',
      caption: 'normal',
      captionBold: 'bold'
    },
    lineHeight: {
      breadCrumbs: '19px',
      button: '20px',
      input: '18px',
      inputLabel: '21px',
      link: '19px',
      h1: '36px',
      h2: '30px',
      h3: '24px',
      h4: '22px',
      h5: '22px',
      body: '24px',
      bodyBold: '24px',
      caption: '18px',
      captionBold: '18px'
    }
  }
};

export default CWBTheme;
