import { getCurUser } from 'cwb-react';
import {
  Change,
  RepresentationData,
  NewRepresentationData
} from 'types/Representation';

export const computeChanges = (
  original: RepresentationData[],
  modified: RepresentationData[],
  newReps: NewRepresentationData[]
) => {
  const changes: Change[] = [];
  const deferred: Change[] = [];

  const processSelfChanges = (
    oSelf: RepresentationData,
    nSelf: RepresentationData | NewRepresentationData
  ) => {
    if (!nSelf) return;

    if (!oSelf || oSelf.contactNo !== nSelf.contactNo) {
      changes.push({ type: 'phone', value: nSelf.contactNo });
    }
    if (!oSelf || oSelf.email !== nSelf.email) {
      changes.push({ type: 'email', value: nSelf.email });
    }
  };

  const processNewRepsChanges = (
    original: RepresentationData[],
    newReps: NewRepresentationData[]
  ) => {
    newReps
      .map((rep) => ({
        agencyId: rep.agency.value,
        agentId: rep.agent.value,
        agencyHomeCity: getCurUser().cityId,
        agencyName: rep.agency.label,
        agentFirstName: rep.agent.firstName,
        agentLastName: rep.agent.lastName,
        phone: rep.contactNo,
        email: rep.email,
        forStudio: false
      }))
      .forEach((agent) => {
        const found = original.find((rep) => {
          return (
            rep.agencyId === agent.agencyId && rep.agentId === agent.agentId
          );
        });

        if (found) {
          deferred.push({ type: 'add', value: agent });
        } else {
          changes.push({ type: 'add', value: agent });
        }
      });
  };

  const processExistingRepsChanges = (
    original: RepresentationData[],
    modified: RepresentationData[]
  ) => {
    const getDiff: (
      original: RepresentationData[],
      modified: RepresentationData[],
      changes: Change[]
    ) => Change[] = (original, modified, changes) => {
      if (!original.length) return changes.reverse();

      if (!modified[0] || original[0].agencyId !== modified[0].agencyId) {
        return getDiff(original.slice(1, original.length), modified, [
          ...changes,
          { type: 'remove', value: original[0] }
        ]);
      } else if (original[0].agentId === modified[0].agentId) {
        return getDiff(
          original.slice(1, original.length),
          modified.slice(1, modified.length),
          changes
        );
      } else {
        return getDiff(
          original.slice(1, original.length),
          modified.slice(1, modified.length),
          [
            ...changes,
            {
              type: 'agent',
              value: {
                ...modified[0],
                oldAgentId: original[0].agentId,
                ...(!modified[0].agentId && {
                  agentFirstName: modified[0].agent.firstName,
                  agentLastName: modified[0].agent.lastName,
                  phone: modified[0].contactNo,
                  email: modified[0].email
                })
              }
            }
          ]
        );
      }
    };

    getDiff(
      JSON.parse(JSON.stringify(original)).reverse(),
      JSON.parse(JSON.stringify(modified)).reverse(),
      []
    ).forEach((c) => changes.push(c));
  };

  // const processDefaultChanges = (
  //   original: RepresentationData[],
  //   modified: RepresentationData[],
  //   newReps: NewRepresentationData[]
  // ) => {
  //   const oldDefault = original.find((o) => o.isDefault);
  //   const newDefault: any = [
  //     ...modified,
  //     ...newReps.map((rep) => ({
  //       ...rep,
  //       agencyId: rep.agency.value,
  //       agentId: rep.agent.value
  //     }))
  //   ].find((item) => item.isDefault);
  //
  //   if (
  //     newDefault &&
  //     (!oldDefault ||
  //       oldDefault.agencyId !== newDefault.agencyId ||
  //       oldDefault.agentId !== newDefault.agentId ||
  //       !newDefault.rosterId)
  //   ) {
  //     changes.push({
  //       type: 'default',
  //       value: newDefault
  //     });
  //   }
  // };

  const processAllChanges = (allChanges: Change[]) => {
    for (let i = 0; i < allChanges.length; i++) {
      if (allChanges[i].type === 'remove') {
        const current = allChanges[i];
        allChanges.splice(i, 1);
        allChanges.unshift(current);
      }
    }
  };

  processSelfChanges(
    original.find((o) => o.isSelf),
    [...modified, ...newReps].find((m) => m.isSelf)
  );
  processNewRepsChanges(original, newReps);
  processExistingRepsChanges(original, modified);
  // this feature is hidden for now, since 'default'/'primary' rep doesn't
  // really mean anything at the moment
  // processDefaultChanges(original, modified, newReps);
  const allChanges: Change[] = [...deferred, ...changes];
  const addChanges: Change[] = allChanges.filter((item) => item.type === 'add');
  if (addChanges.length + original.length > 4) {
    processAllChanges(allChanges);
  }
  return allChanges;
};
