import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Typography
} from 'components';

type Props = {
  isOpen: boolean;
  onClick: () => void;
  onClose: () => void;
};

function UnsavedChangesModal({
  isOpen,
  onClick: handleClick,
  onClose: handleClose
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalHeader destructive onClose={handleClose}>
        {t('You have unsaved changes!')}
      </ModalHeader>
      <ModalBody>
        <Typography color="medGrey">
          {t(
            'Are you sure you would like to leave this page without saving? All changes will be lost.'
          )}
        </Typography>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button destructive onClick={handleClick}>
            {t('Leave page')}
          </Button>
          <Button destructive variant="tertiary" onClick={handleClose}>
            {t('Cancel')}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
}

export default UnsavedChangesModal;
