import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  id?: string;
  className?: string;
  style?: any;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  color?:
    | 'black'
    | 'darkGrey'
    | 'medGrey'
    | 'lightGrey'
    | 'success'
    | 'error'
    | 'link'
    | 'disabled'
    | 'inherit'
    | 'initial'
    | 'unset';
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  gutterBottom?: boolean;
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'body'
    | 'bodyBold'
    | 'caption'
    | 'captionBold';
};

const Typography: React.FC<Props> = ({
  children,
  id,
  className,
  style,
  align = 'inherit',
  color = 'black',
  component = 'p',
  gutterBottom = false,
  variant = 'body'
}) => {
  const props = { id, className, style, align, color, gutterBottom, variant };

  switch (component) {
    case 'h1':
      return <StyledH1 {...props}>{children}</StyledH1>;
    case 'h2':
      return <StyledH2 {...props}>{children}</StyledH2>;
    case 'h3':
      return <StyledH3 {...props}>{children}</StyledH3>;
    case 'h4':
      return <StyledH4 {...props}>{children}</StyledH4>;
    case 'h5':
      return <StyledH5 {...props}>{children}</StyledH5>;
    case 'h6':
      return <StyledH6 {...props}>{children}</StyledH6>;
    case 'p':
    default:
      return <StyledP {...props}>{children}</StyledP>;
    case 'span':
      return <StyledSpan {...props}>{children}</StyledSpan>;
  }
};

const baseStyles = css`
  margin: 0;
  font-family: ${(p) => p.theme.typography.fontFamily};
`;

const StyledTypography = styled.p<Props>`
  ${baseStyles}

  ${(p: any) =>
    ['inherit', 'initial', 'unset'].includes(p.color)
      ? css`
          color: ${p.color};
        `
      : css`
          color: ${(p: any) => p.theme.typography.color[p.color]};
        `}

  ${(p) =>
    p.gutterBottom &&
    css`
      margin-bottom: 0.35em;
    `}

  font-size: ${(p) => p.theme.typography.fontSize[p.variant]};
  font-weight: ${(p) => p.theme.typography.fontWeight[p.variant]};
  line-height: ${(p) => p.theme.typography.lineHeight[p.variant]};

  text-align: ${(p) => p.align};
`;

const StyledH1 = StyledTypography.withComponent('h1');

const StyledH2 = StyledTypography.withComponent('h2');

const StyledH3 = StyledTypography.withComponent('h3');

const StyledH4 = StyledTypography.withComponent('h4');

const StyledH5 = StyledTypography.withComponent('h5');

const StyledH6 = StyledTypography.withComponent('h6');

const StyledP = StyledTypography;

const StyledSpan = StyledTypography.withComponent('span');

export default Typography;
