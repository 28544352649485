import React, { MouseEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';

type Props = {
  id?: string;
  className?: string;
  style?: any;
  destructive?: boolean;
  disabled?: boolean;
  size?: 'small' | 'large';
  startIcon?: ReactNode;
  type?: 'submit' | 'button' | 'reset';
  variant?: 'primary' | 'secondary' | 'tertiary';
  onClick?: (event: MouseEvent) => void;
  onMouseDown?: (event: MouseEvent) => void;
};

const Button: React.FC<Props> = ({
  children,
  id,
  className,
  style,
  destructive,
  disabled,
  size = 'large',
  startIcon,
  type = 'submit',
  variant = 'primary',
  onClick,
  onMouseDown
}) => {
  const props = {
    id,
    className,
    style,
    destructive,
    disabled,
    isChildNodeString: typeof children === 'string',
    size,
    startIcon,
    type,
    variant,
    onClick,
    onMouseDown
  };

  return (
    <StyledButton {...props}>
      {startIcon}
      {children}
    </StyledButton>
  );
};

const baseStyles = css<Props>`
  height: ${(p) => (p.size === 'small' ? '28px' : '36px')};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${(p) => p.theme.spacing(1, 2, 1)};
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const StyledButton = styled.button<Props & { isChildNodeString: boolean }>`
  ${baseStyles}

  ${(p) =>
    p.startIcon &&
    css`
      & > :first-child {
        margin: -0.5px 5px 0.5px 0;
      }
    `}

  ${(p) =>
    p.isChildNodeString &&
    css`
      font-size: ${(p) => p.theme.typography.fontSize.button};
      font-weight: ${(p) => p.theme.typography.fontWeight.button};
      line-height: ${(p) => p.theme.typography.lineHeight.button};
    `}

  ${(p) =>
    p.variant === 'primary' &&
    css`
      color: ${(p) => p.theme.palette.common.white};
      background-color: ${(p) => p.theme.palette.button.normal};

      &:hover {
        background-color: ${(p) => p.theme.palette.button.hover};
      }

      &:active {
        background-color: ${(p) => p.theme.palette.button.pressed};
      }

      ${(p) =>
        p.destructive &&
        css`
          background-color: ${(p) => p.theme.palette.system.error};

          &:hover {
            background-color: ${(p) => p.theme.palette.system.errorDark};
          }

          &:active {
            background-color: ${(p) => p.theme.palette.system.errorDark};
          }
        `}

      &:disabled {
        color: ${(p) => p.theme.palette.common.white};
        background-color: ${(p) => p.theme.palette.common.grey};
        cursor: not-allowed;
      }
    `}

  ${(p) =>
    p.variant === 'secondary' &&
    css`
      color: ${(p) => p.theme.typography.color.darkGrey};
      background-color: ${(p) => p.theme.palette.common.white};
      border: 1px solid ${(p) => p.theme.palette.button.normal};

      &:hover {
        color: ${(p) => p.theme.palette.button.hover};
      }

      &:active {
        color: ${(p) => p.theme.palette.button.pressed};
      }

      &:disabled {
        color: ${(p) => p.theme.palette.grey[4]};
        background-color: ${(p) => p.theme.palette.common.white};
        border-color: ${(p) => p.theme.palette.grey[2]};
        cursor: not-allowed;
      }
    `}

  ${(p) =>
    p.variant === 'tertiary' &&
    css`
      color: ${(p) => p.theme.typography.color.darkGrey};
      background-color: ${(p) => p.theme.palette.common.white};
      border: 1px solid ${(p) => p.theme.palette.grey[3]};

      &:hover {
        color: ${(p) => p.theme.palette.button.hover};
      }

      &:active {
        color: ${(p) => p.theme.palette.button.pressed};
      }

      ${(p) =>
        p.destructive &&
        css`
          &:hover {
            color: ${(p) => p.theme.palette.system.error};
          }

          &:active {
            color: ${(p) => p.theme.palette.system.errorDark};
          }
        `}

      &:disabled {
        color: ${(p) => p.theme.palette.grey[4]};
        background-color: ${(p) => p.theme.palette.common.white};
        border-color: ${(p) => p.theme.palette.grey[2]};
        cursor: not-allowed;
      }
    `}
`;

export default Button;
