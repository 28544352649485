import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Representation from 'pages/Representation';

function Routes() {
  return (
    <Switch>
      <Route exact path="/representation" component={Representation} />
      <Route
        exact
        path="/404"
        render={() => {
          window.location.assign(process.env.REACT_APP_404);
          return null;
        }}
      />
      <Redirect from="*" to="/404" />
    </Switch>
  );
}

export default Routes;
