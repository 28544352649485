import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Typography
} from 'components';

type Props = {
  agencyName: string;
  index: number;
  isDeleteExisting: boolean;
  isOpen: boolean;
  onClose: () => void;
  onDeleteExisting: (targetIndex: number) => void;
  onDeleteNew: (targetIndex: number) => void;
};

function DeleteRepresentationModal({
  agencyName,
  index,
  isDeleteExisting,
  isOpen,
  onClose: handleClose,
  onDeleteExisting,
  onDeleteNew
}: Props) {
  const { t } = useTranslation();

  const handleClick = () => {
    if (isDeleteExisting) {
      onDeleteExisting(index);
    } else {
      onDeleteNew(index);
    }
    handleClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalHeader destructive onClose={handleClose}>
        {t('Remove item?')}
      </ModalHeader>
      <ModalBody>
        <Typography color="medGrey">
          {t('Are you sure you would like to remove')}
          &nbsp;
          {agencyName}?
        </Typography>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button destructive onClick={handleClick}>
            {t('Remove')}
          </Button>
          <Button destructive variant="tertiary" onClick={handleClose}>
            {t('Cancel')}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
}

export default DeleteRepresentationModal;
