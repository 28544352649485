import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

type LabelPosition = 'top' | 'right' | 'bottom' | 'left';

type Props = {
  id?: string;
  className?: string;
  style?: any;
  checked: boolean;
  label: string | ReactNode;
  labelPosition?: LabelPosition;
  name?: string;
  onChange: (event: any) => void;
};

const Toggle: React.FC<Props> = ({
  id,
  className,
  style,
  checked,
  label,
  labelPosition = 'right',
  name = '',
  onChange: handleChange
}) => {
  const props = { id, className, style, labelPosition };

  const handleClick = (checked: boolean) => {
    handleChange({
      target: { name, checked }
    });
  };

  return (
    <StyledDiv {...props}>
      <Outer checked={checked} onClick={() => handleClick(!checked)}>
        <Inner checked={checked} />
      </Outer>
      <StyledInput defaultChecked={checked} name={name} type="checkbox" />
      <StyledLabel
        isChildNodeString={typeof label === 'string'}
        labelPosition={labelPosition}
      >
        {label}
      </StyledLabel>
    </StyledDiv>
  );
};

const StyledDiv = styled.div<{ labelPosition: LabelPosition }>`
  display: flex;

  ${(p) =>
    p.labelPosition === 'top' &&
    css`
      flex-direction: column-reverse;
    `}

  ${(p) =>
    p.labelPosition === 'right' &&
    css`
      flex-direction: row;
    `}

  ${(p) =>
    p.labelPosition === 'bottom' &&
    css`
      flex-direction: column;
    `}

  ${(p) =>
    p.labelPosition === 'left' &&
    css`
      flex-direction: row-reverse;
    `}
`;

const Outer = styled.div<{ checked: boolean }>`
  width: 42px;
  height: 25px;
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${(p) => p.theme.palette.grey[3]}
  border-radius: 25px;
  cursor: pointer;
  transition: all 150ms;

  ${(p) =>
    p.checked &&
    css`
      background-color: ${(p) => p.theme.palette.brand.lightBlue};
    `}
`;

const Inner = styled.div<{ checked: boolean }>`
  width: 17px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(25%, 25%);
  background-color: ${(p) => p.theme.palette.common.white};
  border-radius: 50%;
  transition: all 150ms;

  ${(p) =>
    p.checked &&
    css`
      transform: translate(125%, 25%);
    `}
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledLabel = styled.label<{
  isChildNodeString: boolean;
  labelPosition: LabelPosition;
}>`
  display: flex;
  align-items: center;
  cursor: auto;

  ${(p) =>
    p.isChildNodeString &&
    css`
      color: ${(p) => p.theme.typography.color[0]};
      font-size: ${(p) => p.theme.typography.fontSize.body};
      font-weight: ${(p) => p.theme.typography.fontWeight.normal};
      line-height: ${(p) => p.theme.typography.lineHeight.body};
    `}

  ${(p) =>
    p.labelPosition === 'top' &&
    css`
      margin: ${(p = p.theme.spacing(0, 0, 1))};
    `}

  ${(p) =>
    p.labelPosition === 'right' &&
    css`
      margin: ${(p = p.theme.spacing(0, 0, 0, 1))};
    `}

  ${(p) =>
    p.labelPosition === 'bottom' &&
    css`
      margin: ${(p = p.theme.spacing(1, 0, 0))};
    `}

  ${(p) =>
    p.labelPosition === 'left' &&
    css`
      margin: ${(p = p.theme.spacing(0, 1, 0, 0))};
    `}
`;

export default Toggle;
