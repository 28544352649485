// @ts-nocheck
import React, { ChangeEvent, useState } from 'react';
import AddAgencyAgentModal from './AddAgencyAgentModal';
import EditExistingRow from './EditExistingRow';
import { CustomChangeEvent, DropdownOption } from 'types/shared';
import { AgencyAgent, RepresentationData } from 'types/Representation';

type Props = {
  existingReps: RepresentationData[];
  onChange: (event: ChangeEvent, targetIndex: number) => void;
  // onChangeDefault: (targetIndex: number) => void;
  onDelete: (targetIndex: number) => void;
  onSaveAgencyAgent: (agencyAgent: AgencyAgent, targetIndex: number) => void;
  onSelectAgent: (event: CustomChangeEvent, targetIndex: number) => void;
  setAgentOptions: (options: DropdownOption[], targetIndex: number) => void;
};

function EditExisting({
  existingReps,
  onChange: handleChange,
  // onChangeDefault: handleChangeDefault,
  onDelete: handleDelete,
  onSaveAgencyAgent,
  onSelectAgent: handleSelect,
  setAgentOptions
}: Props) {
  const [modal, setModal] = useState({
    agencyName: '',
    index: 0,
    isOpen: false
  });

  const handleAddAgencyAgent = (agencyName: string, targetIndex: number) => {
    setModal({
      agencyName,
      index: targetIndex,
      isOpen: true
    });
  };

  const handleSave = (agencyAgent: AgencyAgent, targetIndex: number) => {
    onSaveAgencyAgent(agencyAgent, targetIndex);
    setModal((modal) => ({ ...modal, isOpen: false }));
  };

  return (
    <>
      {existingReps.map((row, index) => (
        <EditExistingRow
          key={index}
          index={index}
          row={row}
          existingReps={existingReps}
          onAddAgencyAgent={handleAddAgencyAgent}
          onChange={handleChange}
          // onChangeDefault={handleChangeDefault}
          onDelete={handleDelete}
          onSelectAgent={handleSelect}
          setAgentOptions={setAgentOptions}
        />
      ))}
      {modal.isOpen && (
        <AddAgencyAgentModal
          {...modal}
          addAgentOnly
          onClose={() => setModal((modal) => ({ ...modal, isOpen: false }))}
          onSave={handleSave}
        />
      )}
    </>
  );
}

export default EditExisting;
