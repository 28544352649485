import React, { ChangeEvent, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { IconButton, Select, TextInput } from 'components';
// import { Tooltip } from 'components';
import DeleteIcon from 'images/delete.svg';
// import StarIcon from 'images/star.svg';
// import StarOutlinedIcon from 'images/star_outlined.svg';
import { CustomChangeEvent, DropdownOption } from 'types/shared';
import { RepresentationData, RepresentationError } from 'types/Representation';
import { emailRegex } from 'helpers/validation';

type Props = {
  index: number;
  row: RepresentationData;
  existingReps: RepresentationData[];
  onAddAgencyAgent: (agencyName: string, targetIndex: number) => void;
  onChange: (event: ChangeEvent, targetIndex: number) => void;
  // onChangeDefault: (targetIndex: number) => void;
  onDelete: (targetIndex: number) => void;
  onSelectAgent: (event: CustomChangeEvent, targetIndex: number) => void;
  setAgentOptions: (options: DropdownOption[], targetIndex: number) => void;
} & StateProps &
  DispatchProps;

function EditExistingRow({
  index,
  row,
  existingReps,
  onAddAgencyAgent: handleAdd,
  onChange,
  // onChangeDefault: handleClick,
  onDelete: handleDelete,
  onSelectAgent,
  setAgentOptions,
  errors,
  getAgencyAgentOptions,
  setErrors
}: Props) {
  const { t } = useTranslation();

  useEffect(() => {
    if (!row.agentOptions.length) {
      getAgencyAgentOptions(row.agencyId).then((options: DropdownOption[]) => {
        setAgentOptions(options, index);
      });
    }
  }, [
    row.agentOptions,
    getAgencyAgentOptions,
    row.agencyId,
    setAgentOptions,
    index
  ]);

  const validate = (eventTarget: HTMLInputElement, targetIndex: number) => {
    const _validate = (name: string, value: string | DropdownOption) => {
      switch (name) {
        case 'agent':
          const foundDuplicate = existingReps.find((rep) => {
            return rep.agentId === (value as DropdownOption).value;
          });
          setErrors(
            errors.map((e, index) => {
              return index === targetIndex
                ? {
                    ...e,
                    agent: foundDuplicate ? t('Agent already added.') : ''
                  }
                : e;
            })
          );
          break;
        case 'contactNo':
          setErrors(
            errors.map((e, index) => {
              return index === targetIndex
                ? {
                    ...e,
                    contactNo: value ? '' : t('Phone number is required.')
                  }
                : e;
            })
          );
          break;
        case 'email':
          setErrors(
            errors.map((e, index) => {
              return index === targetIndex
                ? {
                    ...e,
                    email: !value
                      ? t('Email is required.')
                      : !emailRegex.test(value)
                      ? t('Email is invalid.')
                      : ''
                  }
                : e;
            })
          );
          break;
      }
    };

    if (typeof eventTarget.value === 'string') {
      eventTarget.value = eventTarget.value.trim();
    }
    _validate(eventTarget.name, eventTarget.value);
  };

  const handleChange = (e: ChangeEvent, index: number) => {
    validate(e.target as HTMLInputElement, index);
    onChange(e, index);
  };

  const handleSelect = (e: CustomChangeEvent, index: number) => {
    validate(e.target as HTMLInputElement, index);
    onSelectAgent(e, index);
  };

  return (
    <StyledRow>
      <Col xs={4}>
        <TextInput
          // endAdornment={
          //   <Tooltip
          //     id={`tooltip-${index}`}
          //     tip={
          //       row.isDefault
          //         ? t('Primary representation')
          //         : t('Click to set primary representation')
          //     }
          //   >
          //     <StyledIconButton
          //       src={row.isDefault ? StarIcon : StarOutlinedIcon}
          //       onClick={() => handleClick(index)}
          //     />
          //   </Tooltip>
          // }
          disabled
          value={row.agency}
        />
      </Col>
      <Col xs={2}>
        {row.isSelf ? (
          <TextInput disabled value={row.agent.label} />
        ) : (
          <Select
            addOptionText={t('Add new agent')}
            allowAdd
            error={Boolean(errors?.[index]?.agent)}
            errorMessage={errors?.[index]?.agent}
            name="agent"
            options={row.agentOptions}
            value={row.agent}
            onAdd={() => handleAdd(row.agency, index)}
            onSelect={(e) => handleSelect(e, index)}
          />
        )}
      </Col>
      <Col xs={2}>
        <TextInput
          disabled={!row.isSelf}
          error={Boolean(errors?.[index]?.contactNo)}
          errorMessage={errors?.[index]?.contactNo}
          name="contactNo"
          value={row.contactNo}
          onChange={(e) => handleChange(e, index)}
        />
      </Col>
      <Col xs={4}>
        <StyledDiv>
          {row.isSelf ? (
            <TextInput
              error={Boolean(errors?.[index]?.email)}
              errorMessage={errors?.[index]?.email}
              name="email"
              value={row.email}
              onChange={(e) => handleChange(e, index)}
            />
          ) : (
            <TextInput
              disabled
              value={row.email || '*************@*****.com'}
            />
          )}
        </StyledDiv>
        <DeleteIconButton
          src={DeleteIcon}
          onClick={() => handleDelete(index)}
        />
      </Col>
    </StyledRow>
  );
}
const StyledRow = styled(Row)`
  margin-bottom: ${(p) => p.theme.spacing(2)};
`;

// const StyledIconButton = styled(IconButton)`
//   width: inherit;
//   height: inherit;
// `;

const StyledDiv = styled.div`
  width: calc(100% - 24px - 8px);
  align-self: flex-start;
  display: flex;
  flex-direction: column;
`;

const DeleteIconButton = styled(IconButton)`
  align-self: flex-start;
  margin-top: ${(p) => p.theme.spacing('5.5px')};
  margin-left: ${(p) => p.theme.spacing(1)};

  & > img {
    width: 24px;
    height: 24px;
  }
`;

interface StateProps {
  errors: RepresentationError[];
}

const mapState = (state: RootState) =>
  ({
    errors: state.representation.errorsExisting
  } as Partial<Props>);

interface DispatchProps {
  getAgencyAgentOptions: (agencyId: number) => any;
  setErrors: (errors: RepresentationError[]) => any;
}

const mapDispatch = (dispatch: Dispatch) => ({
  getAgencyAgentOptions: dispatch.representation.getAgencyAgentOptions,
  setErrors: dispatch.representation.setErrorsExisting
});

export default connect(mapState, mapDispatch)(EditExistingRow);
