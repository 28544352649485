import React, {
  MouseEvent,
  RefObject,
  useEffect,
  useRef,
  useState
} from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import Typography from './Typography';
import AddIcon from 'images/add.svg';
import AddIconWhite from 'images/add_white.svg';
import DropdownIcon from 'images/double_caret_vertical.svg';
import ErrorIcon from 'images/error.svg';
import { CustomChangeEvent, DropdownOption } from 'types/shared';

type Props = {
  id?: string;
  className?: string;
  style?: any;
  addOptionText?: string;
  allowAdd?: boolean;
  error?: boolean;
  errorMessage?: string;
  isLoading?: boolean;
  name?: string;
  options: DropdownOption[];
  placeholder?: string;
  ref?: any;
  value: DropdownOption;
  onAdd?: () => void;
  onBlur?: (event: CustomChangeEvent) => void;
  onSelect: (event: CustomChangeEvent) => void;
};

const Select: React.FC<Props> = React.forwardRef(
  (
    {
      id,
      className,
      style,
      addOptionText,
      allowAdd,
      error,
      errorMessage,
      isLoading,
      name,
      options,
      placeholder,
      value,
      onAdd: handleAdd,
      onBlur,
      onSelect
    },
    ref
  ) => {
    const { t } = useTranslation();

    const anchorEl = useRef(null);
    const [showMenu, setShowMenu] = useState(false);
    const [imagesToPreload] = useState([AddIcon, AddIconWhite]);

    useEffect(() => {
      imagesToPreload.forEach((imgSrc: string) => {
        const img = new Image();
        img.src = imgSrc;
      });
    }, [imagesToPreload]);

    const handleBlur = () => {
      setShowMenu(false);
      onBlur &&
        onBlur({
          target: { name, value }
        } as CustomChangeEvent);
    };

    const handleClick = () => {
      if (ref) {
        (ref as RefObject<HTMLButtonElement>)?.current?.focus();
      } else {
        anchorEl.current.focus();
      }
    };

    const handleClickOption = (item: DropdownOption) => {
      onSelect({
        target: {
          name,
          value: item
        }
      } as CustomChangeEvent);
      setShowMenu(false);
    };

    const props = {
      id,
      className,
      style
    };

    return (
      <StyledDiv {...props}>
        <StyledButton
          ref={(ref as RefObject<HTMLButtonElement>) || anchorEl}
          error={error}
          hasValue={Boolean(value)}
          type="button"
          onBlur={handleBlur}
          onClick={handleClick}
          onFocus={() => setShowMenu(true)}
        >
          <StyledSpan title={value ? value.label : null}>
            {value ? value.label : placeholder}
          </StyledSpan>
          <StyledDropdownIcon src={DropdownIcon} />
        </StyledButton>
        {showMenu && (
          <Menu>
            {isLoading ? (
              <StyledLi>{t('Loading...')}</StyledLi>
            ) : (
              <>
                {options.length ? (
                  options.map((item) => (
                    <MenuItem
                      key={item.value}
                      onClick={() => handleClickOption(item)}
                      onMouseDown={(e: MouseEvent) => e.preventDefault()}
                    >
                      {item.label}
                    </MenuItem>
                  ))
                ) : (
                  <StyledLi>{t('No options')}</StyledLi>
                )}
                {allowAdd && (
                  <StyledMenuItem
                    onClick={handleAdd}
                    onMouseDown={(e: MouseEvent) => e.preventDefault()}
                  >
                    <Typography component="span" variant="body">
                      {addOptionText ? addOptionText : t('Add option')}
                    </Typography>
                  </StyledMenuItem>
                )}
              </>
            )}
          </Menu>
        )}
        {error && errorMessage && (
          <ErrorContainer>
            <StyledImg src={ErrorIcon} />
            <Typography color="error" variant="captionBold">
              {errorMessage}
            </Typography>
          </ErrorContainer>
        )}
      </StyledDiv>
    );
  }
);

const StyledDiv = styled.div`
  position: relative;
`;

const StyledButton = styled.button<{
  error: boolean;
  hasValue: boolean;
}>`
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  color: ${(p) =>
    p.hasValue
      ? p.theme.typography.color.darkGrey
      : p.theme.typography.color.lightGrey};
  background-color: ${(p) => p.theme.palette.common.white};
  border: 1px solid ${(p) => p.theme.palette.common.grey};
  border-radius: 4px;
  font-size: ${(p) => p.theme.typography.fontSize.input};
  font-weight: ${(p) => p.theme.typography.fontWeight.input};
  line-height: ${(p) => p.theme.typography.lineHeight.input};
  text-align: left;

  &:focus {
    border-color: ${(p) => p.theme.palette.button.normal};
    outline: none;
  }

  ${(p) =>
    p.error &&
    css`
      border-color: ${(p) => p.theme.palette.system.error};
    `}
`;

const StyledSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledDropdownIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: -8px;
`;

const Menu = styled.ul`
  width: 100%;
  max-height: 280px;
  position: absolute;
  top: 40px;
  left: 0;
  padding: ${(p) => p.theme.spacing(0)};
  background-color: ${(p) => p.theme.palette.common.white};
  border: 1px solid ${(p) => p.theme.palette.common.grey};
  border-radius: 4px;
  list-style: none;
  overflow-y: auto;
  z-index: 10;
`;

const baseLiStyles = css`
  padding: ${(p) => p.theme.spacing(0.5, 2)};
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;

  &:first-of-type {
    margin-top: ${(p) => p.theme.spacing(1)};
  }

  &:last-of-type {
    margin-bottom: ${(p) => p.theme.spacing(1)};
  }
`;

const MenuItem = styled.li`
  ${baseLiStyles}

  color: ${(p) => p.theme.typography.color.darkGrey};
  cursor: pointer;

  &:focus,
  &:hover {
    color: ${(p) => p.theme.palette.common.white};
    background-color: ${(p) => p.theme.palette.button.normal};
  }
`;

const StyledLi = styled.li`
  ${baseLiStyles}

  color: ${(p) => p.theme.typography.color.lightGrey};
  cursor: default;
`;

const StyledMenuItem = styled(MenuItem)`
  & > span {
    color: ${(p) => p.theme.palette.button.normal};
  }

  &:hover > span {
    color: ${(p) => p.theme.palette.common.white};
  }

  & > span::before {
    position: relative;
    top: 3px;
    margin-right: ${(p) => p.theme.spacing(0.5)};
    content: url(${AddIcon});
  }

  &:hover > span::before {
    content: url(${AddIconWhite});
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(p) => p.theme.spacing(1)};
`;

const StyledImg = styled.img`
  margin: ${(p) => p.theme.spacing(0, '4px', '2px', 0)};
`;

export default Select;
