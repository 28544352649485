import { useEffect, useState } from 'react';
import { CWBAPI } from 'cwb-react';

function useCWBAPI() {
  const [api, setAPI] = useState(null);

  useEffect(() => {
    (async () => {
      const sharedAPIs = await import(
        `cwb-react/dist/${process.env.REACT_APP_CWB_API_FILE}`
      );
      setAPI(
        new CWBAPI(
          { ...sharedAPIs.default },
          { cwb: process.env.REACT_APP_API }
        )
      );
    })();
  }, []);

  return { api };
}

export default useCWBAPI;
