import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import { ThemeProvider } from 'styled-components';
import CWBTheme from 'theme';
import { BrowserRouter } from 'react-router-dom';
import { BreadCrumbProvider } from 'components';
import App from 'App';
import 'i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'index.css';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={CWBTheme}>
      <BrowserRouter>
        <BreadCrumbProvider>
          <App />
        </BreadCrumbProvider>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);
