import React, { ChangeEvent, Component } from 'react';
import styled from 'styled-components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import {
  Button,
  ButtonGroup,
  TextInput,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Typography
} from 'components';
import { AgencyAgent } from 'types/Representation';
import { emailRegex } from 'helpers/validation';

type Props = {
  addAgentOnly?: boolean;
  agencyId?: number;
  agencyName: string;
  index: number;
  isOpen: boolean;
  onClose: () => void;
  onSave: (agencyAgent: AgencyAgent, targetIndex: number) => void;
} & WithTranslation;

type State = {
  errors: Partial<AgencyAgent>;
  hasClickSubmit: boolean;
} & AgencyAgent;

class AddAgencyAgentModal extends Component<Props, State> {
  state = {
    agencyId: 0,
    agencyName: '',
    agentFirstName: '',
    agentLastName: '',
    agentPhone: '',
    agentEmail: '',
    errors: {
      agencyName: '',
      agentFirstName: '',
      agentLastName: '',
      agentPhone: '',
      agentEmail: ''
    },
    hasClickSubmit: false
  };

  inputEl: HTMLInputElement = null;

  componentDidMount = () => {
    this.setState(
      {
        agencyId: this.props.agencyId || 0,
        agencyName: this.props.agencyName
      },
      () => {
        this.inputEl.focus();
      }
    );
  };

  getErrorMessage = (name: string, value: string) => {
    const { t } = this.props;
    switch (name) {
      case 'agencyName':
        return value ? '' : t('Agency name is required.');
      case 'agentFirstName':
        return value ? '' : t('Agent first name is required.');
      case 'agentLastName':
        return value ? '' : t('Agent last name is required.');
      case 'agentPhone':
        return value ? '' : t('Agent phone number is required.');
      case 'agentEmail':
        return !value
          ? t('Agent email is required.')
          : !emailRegex.test(value)
          ? t('Agent email is invalid.')
          : '';
    }
  };

  validate = (eventTarget: HTMLInputElement) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [eventTarget.name]: this.getErrorMessage(
          eventTarget.name,
          eventTarget.value.trim()
        )
      }
    });
  };

  validateAll = () => {
    const fields = {
      agencyName: this.state.agencyName,
      agentFirstName: this.state.agentFirstName,
      agentLastName: this.state.agentLastName,
      agentPhone: this.state.agentPhone,
      agentEmail: this.state.agentEmail
    };
    const newErrors = { ...this.state.errors } as Partial<AgencyAgent> & {
      [key: string]: string;
    };

    for (const [key, value] of Object.entries(fields)) {
      const errorMessage = this.getErrorMessage(key, value);
      newErrors[key] = errorMessage;
    }

    this.setState({ errors: newErrors });
    return !Boolean(Object.values(newErrors).reduce((acc, cur) => acc + cur));
  };

  handleChange = (e: ChangeEvent) => {
    const eventTarget = e.target as HTMLInputElement;
    this.setState(
      ({
        [eventTarget.name]: eventTarget.value
      } as unknown) as AgencyAgent,
      () => {
        if (this.state.hasClickSubmit) {
          this.validate(eventTarget as HTMLInputElement);
        }
      }
    );
  };

  handleSave = (index: number) => {
    this.setState({ hasClickSubmit: true });
    if (!this.validateAll()) return;

    this.props.onSave(
      {
        agencyId: this.state.agencyId,
        agencyName: this.state.agencyName.trim(),
        agentFirstName: this.state.agentFirstName.trim(),
        agentLastName: this.state.agentLastName.trim(),
        agentPhone: this.state.agentPhone.trim(),
        agentEmail: this.state.agentEmail.trim()
      },
      index
    );
  };

  render = () => {
    const { t, addAgentOnly, index, isOpen, onClose: handleClose } = this.props;

    const {
      agencyName,
      agentFirstName,
      agentLastName,
      agentPhone,
      agentEmail,
      errors
    } = this.state;

    const isSubmittable = !Boolean(
      Object.values(this.state.errors).reduce((acc, cur) => acc + cur)
    );

    return (
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalHeader onClose={handleClose}>
          {addAgentOnly ? t('Add New Agent') : t('Add New Agency and Agent')}
        </ModalHeader>
        <ModalBody>
          <div>
            <Typography gutterBottom variant="h5">
              {t('Agency')}
            </Typography>
            <TextInput
              error={Boolean(errors.agencyName)}
              errorMessage={errors.agencyName}
              label={t('Name')}
              name="agencyName"
              readOnly={addAgentOnly}
              value={agencyName}
              onBlur={(e) => this.validate(e.target as HTMLInputElement)}
              onChange={this.handleChange}
            />
          </div>
          <StyledContainer>
            <Typography gutterBottom variant="h5">
              {t('Agent')}
            </Typography>
            <StyledRow>
              <Col>
                <TextInput
                  ref={(element: HTMLInputElement) => (this.inputEl = element)}
                  error={Boolean(errors.agentFirstName)}
                  errorMessage={errors.agentFirstName}
                  label={t('First Name')}
                  name="agentFirstName"
                  value={agentFirstName}
                  onBlur={(e) => this.validate(e.target as HTMLInputElement)}
                  onChange={this.handleChange}
                />
              </Col>
              <Col>
                <TextInput
                  error={Boolean(errors.agentLastName)}
                  errorMessage={errors.agentLastName}
                  label={t('Last Name')}
                  name="agentLastName"
                  value={agentLastName}
                  onBlur={(e) => this.validate(e.target as HTMLInputElement)}
                  onChange={this.handleChange}
                />
              </Col>
            </StyledRow>
            <StyledRow>
              <Col>
                <TextInput
                  error={Boolean(errors.agentPhone)}
                  errorMessage={errors.agentPhone}
                  label={t('Phone')}
                  name="agentPhone"
                  value={agentPhone}
                  onBlur={(e) => this.validate(e.target as HTMLInputElement)}
                  onChange={this.handleChange}
                />
              </Col>
              <Col>
                <TextInput
                  error={Boolean(errors.agentEmail)}
                  errorMessage={errors.agentEmail}
                  label={t('Email')}
                  name="agentEmail"
                  value={agentEmail}
                  onBlur={(e) => this.validate(e.target as HTMLInputElement)}
                  onChange={this.handleChange}
                />
              </Col>
            </StyledRow>
          </StyledContainer>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              disabled={!isSubmittable}
              onMouseDown={() => this.handleSave(index)}
            >
              {t('Save')}
            </Button>
            <Button type="button" variant="secondary" onMouseDown={handleClose}>
              {t('Cancel')}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    );
  };
}

const StyledContainer = styled(Container)`
  margin-top: ${(p) => p.theme.spacing(2)};
  padding: 0;
`;

const StyledRow = styled(Row)`
  &:not(:last-child) {
    margin-bottom: ${(p) => p.theme.spacing(1)};
  }

  & > div.col:first-child {
    padding-right: ${(p) => p.theme.spacing(1)};
  }

  & > div.col:last-child {
    padding-left: ${(p) => p.theme.spacing(1)};
  }
`;

export default withTranslation()(AddAgencyAgentModal);
