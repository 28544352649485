import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { IconButton, Autocomplete, Select, TextInput } from 'components';
import DeleteIcon from 'images/delete.svg';
// import SearchIcon from 'images/search.svg';
// import StarIcon from 'images/star.svg';
// import StarOutlinedIcon from 'images/star_outlined.svg';
import { CustomChangeEvent, DropdownOption } from 'types/shared';
import {
  NewRepresentationData,
  RepresentationData,
  RepresentationError
} from 'types/Representation';
import { debounce } from 'helpers';
import { emailRegex } from 'helpers/validation';

type Props = {
  index: number;
  row: NewRepresentationData;
  existingReps: RepresentationData[];
  newReps: NewRepresentationData[];
  onAddAgencyAgent: (
    agencyId: number,
    agencyName: string,
    targetIndex: number
  ) => void;
  onChange: (event: ChangeEvent, targetIndex: number) => void;
  // onChangeDefault: (targetIndex: number) => void;
  onDelete: (targetIndex: number) => void;
  onSelectAgency: (e: CustomChangeEvent, targetIndex: number) => void;
  onSelectAgent: (e: CustomChangeEvent, targetIndex: number) => void;
  setAgencyOptions: (options: DropdownOption[], targetIndex: number) => void;
  setAgentOptions: (options: DropdownOption[], targetIndex: number) => void;
} & StateProps &
  DispatchProps;

function EditNewRow({
  index,
  row,
  existingReps,
  newReps,
  onAddAgencyAgent: handleAddAgencyAgent,
  onChange,
  // onChangeDefault: handleClick,
  onDelete: handleDelete,
  onSelectAgency,
  onSelectAgent,
  setAgencyOptions,
  setAgentOptions,
  actorProfile,
  errors,
  getAgencyOptions,
  getAgencyAgentOptions,
  setErrors
}: Props) {
  const { t } = useTranslation();

  const selectEl = useRef(null);
  const [isLoadingAgency, setIsLoadingAgency] = useState(false);
  const [isLoadingAgent, setIsLoadingAgent] = useState(false);

  const selfAgencyId = actorProfile.profileDefaults[0].agencyId;

  const validate = (eventTarget: HTMLInputElement, targetIndex: number) => {
    const _validate = (name: string, value: string | DropdownOption) => {
      switch (name) {
        case 'agencyInput':
          let agencyInputError = '';
          if (!value) {
            agencyInputError = t('Agency is required.');
          } else if (value === t('Self Represented')) {
            const reps = [
              ...existingReps.map((rep) => ({
                agencyId: rep.agencyId,
                isExisting: true
              })),
              ...newReps.map((rep) => ({
                agencyId: rep.agency?.value,
                isExisting: false
              }))
            ];
            const duplicateIndex = reps.findIndex((rep) => {
              return rep.agencyId === selfAgencyId;
            });
            if (
              duplicateIndex > -1 &&
              duplicateIndex - existingReps.length !== targetIndex
            ) {
              agencyInputError = t('You are already self represented.');
            }
          }
          setErrors(
            errors.map((e, index) => {
              return index === targetIndex
                ? {
                    ...e,
                    agencyInput: agencyInputError
                  }
                : e;
            })
          );
          break;
        case 'agent':
          let agentError = '';
          if (!value) {
            agentError = t('Agent is required.');
          } else {
            const reps = [
              ...existingReps.map((rep) => ({
                agencyId: rep.agencyId,
                agentId: rep.agentId,
                isExisting: true
              })),
              ...newReps.map((rep) => ({
                agencyId: rep.agency?.value,
                agentId: rep.agent?.value,
                isExisting: false
              }))
            ];
            const duplicateIndex = reps.findIndex((rep) => {
              return rep.agentId === (value as DropdownOption).value;
            });
            if (
              duplicateIndex > -1 &&
              duplicateIndex - existingReps.length !== targetIndex
            ) {
              agentError = t('Agent already added.');
            }
          }
          setErrors(
            errors.map((e, index) => {
              return index === targetIndex
                ? {
                    ...e,
                    agent: agentError
                  }
                : e;
            })
          );
          break;
        case 'contactNo':
          setErrors(
            errors.map((e, index) => {
              return index === targetIndex
                ? {
                    ...e,
                    contactNo: value ? '' : t('Phone number is required.')
                  }
                : e;
            })
          );
          break;
        case 'email':
          setErrors(
            errors.map((e, index) => {
              return index === targetIndex
                ? {
                    ...e,
                    email: !value
                      ? t('Email is required.')
                      : !emailRegex.test(value)
                      ? t('Email is invalid.')
                      : ''
                  }
                : e;
            })
          );
          break;
      }
    };

    if (typeof eventTarget.value === 'string') {
      eventTarget.value = eventTarget.value.trim();
    }
    _validate(eventTarget.name, eventTarget.value);
  };

  const _getAgencyOptions = useCallback(
    debounce((agencyName: string, index: number) => {
      if (agencyName.length >= 2) {
        getAgencyOptions(agencyName).then((options: DropdownOption[]) => {
          setAgencyOptions(
            [
              {
                label: t('Self Represented'),
                value: selfAgencyId
              } as DropdownOption,
              ...options
            ],
            index
          );
          setIsLoadingAgency(false);
        });
      }
    }, 500),
    []
  );

  const handleChangeAgency = (e: ChangeEvent, index: number) => {
    const eventTarget = e.target as HTMLInputElement;
    _getAgencyOptions(eventTarget.value, index);
    onChange(e, index);
    setIsLoadingAgency(true);
  };

  const handleChangeSelf = (e: ChangeEvent, index: number) => {
    validate(e.target as HTMLInputElement, index);
    onChange(e, index);
  };

  const handleSelectAgency = (e: CustomChangeEvent, targetIndex: number) => {
    const { value } = e.target;
    if (value?.value === selfAgencyId) {
      validate(
        {
          name: 'agencyInput',
          value: t('Self Represented')
        } as HTMLInputElement,
        targetIndex
      );
    }
    if (value) {
      setIsLoadingAgent(true);
      if (selectEl.current && value.value !== selfAgencyId) {
        selectEl.current.focus();
      }
      getAgencyAgentOptions(value.value).then((options: DropdownOption[]) => {
        setAgentOptions(options, targetIndex);
        setIsLoadingAgent(false);
      });
    }
    onSelectAgency(e, targetIndex);
  };

  const handleSelectAgent = (e: CustomChangeEvent, index: number) => {
    validate(e.target as HTMLInputElement, index);
    onSelectAgent(e, index);
  };

  // const getEndAdornment = (row: NewRepresentationData, index: number) => {
  //   if (row.agency && (row.agent || row.agency.value === selfAgencyId)) {
  //     return row.isDefault ? (
  //       <IconButton src={StarIcon} onClick={() => handleClick(index)} />
  //     ) : (
  //       <IconButton src={StarOutlinedIcon} onClick={() => handleClick(index)} />
  //     );
  //   }
  //   return <StyledImg src={SearchIcon} />;
  // };

  return (
    <StyledRow>
      <Col xs={4}>
        {row.agency && !row.agency.value ? (
          <TextInput
            disabled
            // endAdornment={getEndAdornment(row, index)}
            value={row.agency.label}
          />
        ) : (
          <Autocomplete
            allowAdd={
              !row.agencyOptions.find((o) => o.label === row.agencyInput)
            }
            isLoading={isLoadingAgency}
            name="agency"
            noFilter
            options={row.agencyOptions}
            renderInput={(props) => (
              <TextInput
                {...props}
                autoComplete="new-password"
                autoFocus
                // endAdornment={getEndAdornment(row, index)}
                error={Boolean(errors?.[index]?.agencyInput)}
                errorMessage={errors?.[index]?.agencyInput}
                name="agencyInput"
                placeholder={t('Search Agency')}
                value={row.agencyInput}
                onBlur={(e) => {
                  props.onBlur(e);
                  validate(e.target as HTMLInputElement, index);
                }}
                onChange={(e) => handleChangeAgency(e, index)}
              />
            )}
            value={row.agency}
            onAdd={() => handleAddAgencyAgent(0, row.agencyInput.trim(), index)}
            onSelect={(e) => handleSelectAgency(e, index)}
          />
        )}
      </Col>
      <Col xs={2}>
        {row.agent && !row.agent.value ? (
          <TextInput disabled value={row.agent.label} />
        ) : row.agency?.value === selfAgencyId ? (
          <TextInput
            disabled
            error={Boolean(errors?.[index]?.agencyInput)}
            value={t('Self')}
          />
        ) : (
          <Select
            ref={selectEl}
            addOptionText={t('Add new agent')}
            allowAdd={row.agency}
            error={Boolean(errors?.[index]?.agent)}
            errorMessage={errors?.[index]?.agent}
            isLoading={isLoadingAgent}
            name="agent"
            options={row.agentOptions}
            placeholder={row.agency ? t('Select one') : t('Agent Name')}
            value={row.agent}
            onAdd={() =>
              handleAddAgencyAgent(row.agency.value, row.agency.label, index)
            }
            onBlur={(e) => validate(e.target as HTMLInputElement, index)}
            onSelect={(e) => handleSelectAgent(e, index)}
          />
        )}
      </Col>
      <Col xs={2}>
        {row.agency?.value === selfAgencyId ? (
          <TextInput
            error={Boolean(errors?.[index]?.contactNo)}
            errorMessage={errors?.[index]?.contactNo}
            name="contactNo"
            value={row.contactNo}
            onChange={(e) => handleChangeSelf(e, index)}
          />
        ) : (
          <TextInput
            disabled
            placeholder={row.agent ? '' : t('Phone number')}
            value={row.contactNo}
          />
        )}
      </Col>
      <Col xs={4}>
        <StyledDiv>
          {row.agency?.value === selfAgencyId ? (
            <TextInput
              error={Boolean(errors?.[index]?.email)}
              errorMessage={errors?.[index]?.email}
              name="email"
              value={row.email}
              onChange={(e) => handleChangeSelf(e, index)}
            />
          ) : (
            <TextInput
              disabled
              placeholder={t('Email')}
              value={row.agent ? row.email || '*************@*****.com' : ''}
            />
          )}
        </StyledDiv>
        <DeleteIconButton
          isValidatable
          src={DeleteIcon}
          onClick={() => handleDelete(index)}
        />
      </Col>
    </StyledRow>
  );
}

const StyledRow = styled(Row)`
  margin-bottom: ${(p) => p.theme.spacing(2)};
`;

const StyledDiv = styled.div`
  width: calc(100% - 24px - 8px);
  align-self: flex-start;
  display: flex;
  flex-direction: column;
`;

const DeleteIconButton = styled(IconButton)<{ isValidatable: boolean }>`
  ${(p) =>
    p.isValidatable &&
    css`
      align-self: flex-start;
      margin-top: ${(p) => p.theme.spacing('5.5px')};
    `}

  margin-left: ${(p) => p.theme.spacing(1)};

  & > img {
    width: 24px;
    height: 24px;
  }
`;

// const StyledImg = styled.img`
//   && {
//     width: 24px;
//     height: 24px;
//     transform: translate(-5px, -6px);
//   }
// `;

interface StateProps {
  actorProfile: any;
  errors: RepresentationError[];
}

const mapState = (state: RootState) =>
  ({
    actorProfile: state.app.actorProfile,
    errors: state.representation.errorsNew
  } as Partial<Props>);

interface DispatchProps {
  getAgencyOptions: (name: string) => any;
  getAgencyAgentOptions: (agencyId: number) => any;
  setErrors: (errors: RepresentationError[]) => any;
}

const mapDispatch = (dispatch: Dispatch) => ({
  getAgencyOptions: dispatch.representation.getAgencyOptions,
  getAgencyAgentOptions: dispatch.representation.getAgencyAgentOptions,
  setErrors: dispatch.representation.setErrorsNew
});

export default connect(mapState, mapDispatch)(EditNewRow);
