import { Models } from '@rematch/core';
import app from './app';
import representation from './representation';

export interface RootModel extends Models<RootModel> {
  app: typeof app;
  representation: typeof representation;
}

export const models: RootModel = {
  app,
  representation
};
