import React from 'react';
import styled from 'styled-components';

type Props = {
  id?: string;
  className?: string;
  style?: any;
};

const Paper: React.FC<Props> = ({ children, id, className, style }) => {
  const props = { id, className, style };

  return <StyledDiv {...props}>{children}</StyledDiv>;
};

const StyledDiv = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background-color: ${(p) => p.theme.palette.common.white};
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

export default Paper;
