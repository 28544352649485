import React from 'react';
import styled from 'styled-components';

type Props = {
  id?: string;
  className?: string;
  style?: any;
};

const Page: React.FC<Props> = ({ children, id, className, style }) => {
  const props = {
    id,
    className,
    style
  };

  return <StyledDiv {...props}>{children}</StyledDiv>;
};

const StyledDiv = styled.div`
  min-height: calc(100% - 95px);
  padding: ${(p) => p.theme.spacing(3, 0)};
  background-color: #fafafa;
  box-sizing: border-box;
`;

export default Page;
