import React from 'react';
import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCellHead,
  TableCell,
  // Tooltip,
  Typography
} from 'components';
// import StarIcon from 'images/star.svg';
import { RepresentationData } from 'types/Representation';

type Props = {} & StateProps & DispatchProps;

function RepresentationTable({ reps }: Props) {
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCellHead>{t('Agency')}</TableCellHead>
          <TableCellHead>{t('Agent')}</TableCellHead>
          <TableCellHead>{t('Contact No.')}</TableCellHead>
          <TableCellHead>{t('Email')}</TableCellHead>
        </TableRow>
      </TableHead>
      <StyledTableBody>
        {!reps.length ? (
          <TableRow>
            <TableCell colSpan={4}>
              <StyledTypography align="center" color="lightGrey" variant="h4">
                {t('Your representation list is currently empty.')}
              </StyledTypography>
            </TableCell>
          </TableRow>
        ) : (
          reps.map((row, index) => (
            <TableRow key={index}>
              {/*
            <StyledTableCell>
              {row.agency}
              {row.isDefault && (
                <StyledTooltip tip={t('Primary representation')}>
                  <img alt={t('Primary representation')} src={StarIcon} />
                </StyledTooltip>
              )}
            </StyledTableCell>
            */}
              <TableCell>{row.agency}</TableCell>
              <TableCell>{row.agent.label}</TableCell>
              <TableCell>{row.contactNo}</TableCell>
              <TableCell>{row.email || '*************@*****.com'}</TableCell>
            </TableRow>
          ))
        )}
      </StyledTableBody>
    </Table>
  );
}

const StyledTableBody = styled(TableBody)`
  & td:first-child {
    color: ${(p) => p.theme.typography.color.black};
    font-weight: 700;
  }
`;

const StyledTypography = styled(Typography)`
  margin: ${(p) => p.theme.spacing(2, 0)};
`;

// const StyledTableCell = styled(TableCell)`
//   width: 100%;
//   height: inherit;
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   box-sizing: border-box;
// `;

// const StyledTooltip = styled(Tooltip)`
//   margin-left: ${(p) => p.theme.spacing(0.5)};
// `;

interface StateProps {
  reps: RepresentationData[];
}

const mapState = (state: RootState) => ({
  reps: state.representation.reps
});

interface DispatchProps {}

const mapDispatch = (dispatch: Dispatch) => ({});

export default connect(mapState, mapDispatch)(RepresentationTable);
