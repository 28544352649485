// @ts-nocheck
import React, { ChangeEvent, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import AddAgencyAgentModal from './AddAgencyAgentModal';
import EditNewRow from './EditNewRow';
import { CustomChangeEvent, DropdownOption } from 'types/shared';
import {
  AgencyAgent,
  NewRepresentationData,
  RepresentationData,
  RepresentationError
} from 'types/Representation';

type Props = {
  existingReps: RepresentationData[];
  newReps: NewRepresentationData[];
  onChange: (event: ChangeEvent, targetIndex: number) => void;
  // onChangeDefault: (targetIndex: number) => void;
  onDelete: (targetIndex: number) => void;
  onSaveAgencyAgent: (agencyAgent: AgencyAgent, targetIndex: number) => void;
  onSelectAgency: (e: CustomChangeEvent, targetIndex: number) => void;
  onSelectAgent: (e: CustomChangeEvent, targetIndex: number) => void;
  setAgencyOptions: (options: DropdownOption[], targetIndex: number) => void;
  setAgentOptions: (options: DropdownOption[], targetIndex: number) => void;
} & StateProps &
  DispatchProps;

function EditNew({
  existingReps,
  newReps,
  onChange: handleChange,
  // onChangeDefault: handleClick,
  onDelete: handleDelete,
  onSaveAgencyAgent,
  onSelectAgency: handleSelectAgency,
  onSelectAgent: handleSelectAgent,
  setAgencyOptions,
  setAgentOptions,
  errorsNew,
  setErrorsNew
}: Props) {
  const [modal, setModal] = useState({
    addAgentOnly: false,
    agencyId: 0,
    agencyName: '',
    index: 0,
    isOpen: false
  });

  const handleAddAgencyAgent = (
    agencyId: number,
    agencyName: string,
    index: number
  ) => {
    setModal((modal) => ({
      ...modal,
      addAgentOnly: Boolean(agencyId),
      agencyId,
      agencyName,
      index,
      isOpen: true
    }));
  };

  const handleSaveAgencyAgent = (
    agencyAgent: AgencyAgent,
    targetIndex: number
  ) => {
    onSaveAgencyAgent(agencyAgent, targetIndex);
    setErrorsNew(
      errorsNew.map((error, index) => {
        return index === targetIndex
          ? {
              ...error,
              agent: ''
            }
          : error;
      })
    );
    setModal((modal) => ({ ...modal, isOpen: false }));
  };

  return (
    <>
      {newReps.map((row, index) => (
        <EditNewRow
          key={index}
          row={row}
          existingReps={existingReps}
          newReps={newReps}
          index={index}
          onAddAgencyAgent={handleAddAgencyAgent}
          onChange={handleChange}
          // onChangeDefault={handleClick}
          onDelete={handleDelete}
          onSelectAgency={handleSelectAgency}
          onSelectAgent={handleSelectAgent}
          setAgencyOptions={setAgencyOptions}
          setAgentOptions={setAgentOptions}
        />
      ))}
      {modal.isOpen && (
        <AddAgencyAgentModal
          {...modal}
          onClose={() => setModal((modal) => ({ ...modal, isOpen: false }))}
          onSave={handleSaveAgencyAgent}
        />
      )}
    </>
  );
}

interface StateProps {
  errorsNew: RepresentationError[];
}

const mapState = (state: RootState) => ({
  errorsNew: state.representation.errorsNew
});

interface DispatchProps {
  setErrorsNew: (errors: RepresentationError[]) => any;
}

const mapDispatch = (dispatch: Dispatch) => ({
  setErrorsNew: dispatch.representation.setErrorsNew
});

export default connect(mapState, mapDispatch)(EditNew);
