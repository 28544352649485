import { createModel } from '@rematch/core';
import { RootModel } from 'models';
import { ProfileManagementAPI } from 'apis';
import { getCurUser, setUserInfo } from 'cwb-react';
import { DropdownOption } from 'types/shared';
import {
  Change,
  RepresentationData,
  RepresentationError
} from 'types/Representation';
import i18n from 'i18n';

interface State {
  errorsExisting: RepresentationError[];
  errorsNew: RepresentationError[];
  isLoading: boolean;
  reps: RepresentationData[];
}

const representation = createModel<RootModel>()({
  state: {
    errorsExisting: [],
    errorsNew: [],
    isLoading: true,
    reps: []
  } as State,
  reducers: {
    setErrorsExisting(state, errorsExisting: RepresentationError[]) {
      return { ...state, errorsExisting };
    },
    setErrorsNew(state, errorsNew: RepresentationError[]) {
      return { ...state, errorsNew };
    },
    setIsLoading(state, isLoading: boolean) {
      return { ...state, isLoading };
    },
    setReps(state, reps: RepresentationData[]) {
      return { ...state, reps };
    }
  },
  effects: (dispatch: any) => ({
    async getRepresentationData({
      user,
      actorProfile
    }: {
      user: any;
      actorProfile: any;
    }) {
      const selfAgencyId = actorProfile.profileDefaults[0].agencyId;
      const selfAgentId = actorProfile.profileDefaults[0].agentId;

      return ProfileManagementAPI.getActorProfileAll(user.id.userId)
        .then((res) => res.json())
        .then((data) => {
          data = data.map((item: any) => {
            const isSelf =
              item.agencyId === selfAgencyId && item.agentId === selfAgentId;
            return {
              rosterId: item.rosterId,
              agencyId: item.agencyId,
              agency: isSelf
                ? i18n.t('Self Represented')
                : item.agency.agencyName,
              agentId: item.agentId,
              agent: {
                label: isSelf
                  ? i18n.t('Self')
                  : `${item.agent.firstName} ${item.agent.lastName}`,
                value: item.agentId,
                firstName: item.agent.firstName,
                lastName: item.agent.lastName,
                contactNo: isSelf ? user.phone || '' : item.agent.phone || '',
                email: isSelf ? user.email || '' : item.agent.email || ''
              },
              agentOptions: [],
              contactNo: isSelf ? user.phone || '' : item.agent.phone || '',
              email: isSelf ? user.email || '' : item.agent.email || '',
              isDefault: item.isDefault,
              isSelf
            } as RepresentationData;
          });
          dispatch.representation.setReps(data);
        })
        .catch(console.error);
    },
    async getAgencyOptions(name: string) {
      return ProfileManagementAPI.getAgencies(name)
        .then((res) => res.json())
        .then((data) => {
          if (!data.records) return [];
          return data.records
            .filter((item: any) =>
              item.name.toLowerCase().includes(name.toLowerCase())
            )
            .map(
              (item: any) =>
                ({
                  label: item.name,
                  value: item.agencyId
                } as DropdownOption)
            );
        })
        .catch(console.error);
    },
    async getAgencyAgentOptions(agencyId: number) {
      return ProfileManagementAPI.getAgencyAgents(agencyId)
        .then((res) => res.json())
        .then((data) => {
          return data.map((item: any) => ({
            label: `${item.firstName} ${item.lastName}`,
            value: item.agentId,
            firstName: item.firstName,
            lastName: item.lastName,
            contactNo: item.phone || '',
            email: item.email || ''
          }));
        })
        .catch(console.error);
    },
    async saveChanges({
      user,
      changes,
      actorProfile
    }: {
      user: any;
      changes: Change[];
      actorProfile: any;
    }) {
      const { userId: clientId } = user.id;
      const records: {
        rosterId: number;
        agencyId: number;
        agencyName: string;
        agentId: number;
        agentName: string;
      }[] = [];

      const getNextPromise = (change: Change, currentIndex: Number) => {
        switch (change.type) {
          case 'phone':
            setUserInfo({
              ...getCurUser(),
              phone: change.value
            });
            return ProfileManagementAPI.updatePhone(
              clientId,
              change.value
            ).then((result) => {
              if (!result.ok) {
                return Promise.reject(result);
              }
              return result;
            });
          case 'email':
            setUserInfo({
              ...getCurUser(),
              email: change.value
            });
            return ProfileManagementAPI.updateEmail(
              clientId,
              change.value
            ).then((result) => {
              if (!result.ok) {
                return Promise.reject(result);
              }
              return result;
            });
          case 'add':
            const { value: payload } = change;
            if (!payload.agencyId) {
              const found = records.find(
                (r) => r.agencyName === payload.agencyName
              );
              if (found) payload.agencyId = found.agencyId;
            }

            return new Promise((res, rej) => {
              ProfileManagementAPI.addClientAgent(clientId, payload)
                .then((result) => {
                  if (!result.ok) {
                    return Promise.reject(result);
                  }
                  return result.json();
                })
                .then((data) => {
                  records.push({
                    rosterId: data.rosterId,
                    agencyId: data.agencyId,
                    agencyName: data.agency ? data.agency.agencyName : '',
                    agentId: data.agentId,
                    agentName: `${data.agent.firstName} ${data.agent.lastName}`.trim()
                  });
                  res(data);
                  return data;
                })
                .catch(rej);
            });
          // case 'default':
          //   if (!change.value.rosterId) {
          //     const selfAgencyId = actorProfile.profileDefaults[0].agencyId;
          //     const selfAgentId = actorProfile.profileDefaults[0].agentId;
          //
          //     if (
          //       change.value.agencyId === selfAgencyId &&
          //       change.value.agentId === selfAgentId
          //     ) {
          //       change.value.rosterId = records.find((r) => {
          //         return (
          //           r.agencyId === selfAgencyId && r.agentId === selfAgentId
          //         );
          //       }).rosterId;
          //     } else {
          //       const found = records.find((r) => {
          //         return (
          //           r.agencyName === change.value.agency.label &&
          //           r.agentName === change.value.agent.label
          //         );
          //       });
          //       change.value.rosterId = found.rosterId;
          //     }
          //   }
          //
          //   return ProfileManagementAPI.updateFavorite(
          //     clientId,
          //     change.value.rosterId
          //   );
          case 'remove':
            return new Promise((res, rej) => {
              ProfileManagementAPI.deleteAgentProfile(
                clientId,
                change.value.agentId
              )
                .then((result) => {
                  if (!result.ok) return Promise.reject(result);
                  return result.json();
                })
                .then(res)
                .catch(rej);
            });
          case 'agent':
            const optionalPayload = {
              agentFirstName: change.value.agentFirstName,
              agentLastName: change.value.agentLastName,
              phone: change.value.phone,
              email: change.value.email
            };

            return new Promise((res, rej) => {
              ProfileManagementAPI.updateClientAgent(clientId, {
                agencyId: change.value.agencyId,
                existentAgentId: change.value.oldAgentId,
                rosterId: change.value.rosterId,
                newAgentId: change.value.agentId,
                ...(!change.value.agentId && optionalPayload)
              })
                .then((result) => {
                  if (!result.ok) return Promise.reject(result);

                  return result.json();
                })
                .then(res)
                .catch(rej);
            });
        }
      };

      return changes.reduce(
        async (
          previousPromise: Promise<any>,
          nextChange: Change,
          currentIndex: number
        ) => {
          await previousPromise;
          return getNextPromise(nextChange, currentIndex);
        },
        Promise.resolve()
      );
    }
  })
});

export default representation;
