import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { setUserAuthToken, setUserInfo } from 'cwb-react';
import {
  BreadCrumb,
  BreadCrumbPortal,
  Container,
  ToastContainer,
  Typography
} from 'components';
import { useCWBAPI } from 'hooks';
import Routes from './Routes';
import { getLanguageCode, setLanguage } from 'helpers';

type Props = {} & StateProps & DispatchProps;

function App({ pageTitle, getActorProfile }: Props) {
  const { api } = useCWBAPI();
  const { t } = useTranslation();

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (api) {
      (async () => {
        try {
          const tokenResp = await api.user.getAuthToken();
          await setUserAuthToken(tokenResp.data.accessToken);
          const userResp = await api.user.getCurrentUser();
          if (userResp.data.id.userTypeId !== 8) {
            throw new Error('User type is not actor.');
          }
          await setUserInfo(userResp.data);
          await getActorProfile(userResp.data.id.userId);
          setLanguage(getLanguageCode(userResp.data.languageChoice));
          setIsReady(true);
        } catch (e) {
          window.top.location.href = process.env.REACT_APP_403;
        }
      })();
    }
  }, [api, getActorProfile]);

  return (
    <>
      <BreadCrumb external to="/">
        {t('Profile')}
      </BreadCrumb>
      <ToastContainer />
      <StyledHeader>
        <StyledContainer>
          <BreadCrumbPortal />
          <Typography component="h1" variant="h2">
            {pageTitle}
          </Typography>
        </StyledContainer>
      </StyledHeader>
      {isReady && <Routes />}
    </>
  );
}

const StyledHeader = styled.header`
  height: 95px;
  padding: ${(p) => p.theme.spacing(2, 0)};
  background-color: ${(p) => p.theme.palette.common.white};
  box-shadow: inset 0 -1px 0 #e7e7f0;
  box-sizing: border-box;
`;

const StyledContainer = styled(Container)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

interface StateProps {
  pageTitle: string;
}

const mapState = (state: RootState) =>
  ({
    pageTitle: state.app.pageTitle
  } as Partial<Props>);

interface DispatchProps {
  getActorProfile: (clientId: number) => void;
}

const mapDispatch = (dispatch: Dispatch) => ({
  getActorProfile: dispatch.app.getActorProfile
});

export default connect(mapState, mapDispatch)(App);
